import Exhibition from '../../models/exhibition/Exhibition';
import {
  Box,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import { ApiServer } from '../../utils/axios';
import styles from './ExhibitionArtWorkCard.module.css';
import useWindowDimensions from 'utils/useWindowDimension';
import { useSelector } from 'react-redux';
import { RootState } from 'store/redux';

const testImgUrl =
  'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/2022poster/soma-poster-rimeui-blackstartjj-22.06.15.jpeg';

type ExhibitionArtWorkCardProps = {
  exhibition: Exhibition;
};

function ExhibitionArtWorkCard({ exhibition }: ExhibitionArtWorkCardProps) {
  const { width, height } = useWindowDimensions();
  const lang = useSelector((state: RootState) => state.lang.lang);

  return (
    <Grid item xs={12} sm={6}>
      <Card
        sx={{
          marginX: { xs: '3rem', md: '0' },
          height: width <= 400 ? '520px' : '620px',
          boxShadow: 0, // elevate 효과 추가
          borderRadius: 0, // 뾰족한 border 설정
        }}
        className={styles.onCard}
      >
        <CardMedia
          image={testImgUrl}
          title="picture"
          sx={{ height: width <= 400 ? '400px' : '500px' }}
        />
        <CardContent>
          {/* 일단 삼항 연산자 이지만 앞으로 지원언어가 많아지면 다르게 처리*/}
          <Typography variant="body2">
            {lang === 'en' ? exhibition.titleEn : exhibition.titleKo}
          </Typography>
          <Typography variant="body2">
            <em>
              <b>
                {exhibition.startDate} ~ {exhibition.endDate}
              </b>
            </em>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ExhibitionArtWorkCard;
