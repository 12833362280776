import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialLoadingState = {
  loading: false
};

export const GLOBAL_LOADING = "LOADING"
export const GLOBAL_LOADED = "LOADED"

type loadingPayload = {
    loadingType: string
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialLoadingState,
  reducers: {
    setLoading(state, action: PayloadAction<string>){
        switch(action.payload){
            case GLOBAL_LOADING: {
                state.loading = true;
                break;
            }
            case GLOBAL_LOADED: {
                state.loading = false;
                break;
            }
        }
    }
  },
});

export const loadingActions = loadingSlice.actions;
export default loadingSlice.reducer;
