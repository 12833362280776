import React, { useCallback } from 'react';
import { Typography, Button, Box, Modal, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import CartModalItem from './CartModalItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/redux';
import { cartActions } from 'store/cart-redux';
import { useTranslation } from 'react-i18next';
import { BasicResponse } from 'models/common';
import { ArtworkOrder } from 'models/order';
import { ApiServer } from 'utils/axios';
import { useNavigate } from 'react-router-dom';

type CartModalProps = {
  onClose: () => void;
  onOpen: () => void;
  isCartOpen: boolean;
};

const CartModal = ({ onClose, onOpen, isCartOpen }: CartModalProps) => {
  const dispatch = useDispatch();
  const { cartArtworks, count, loading, error } = useSelector(
    (state: RootState) => state.cart
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDeleteItem = (id: number) => {
    const artworkToRemove = cartArtworks.find((artwork) => artwork.id === id);
    if (artworkToRemove) {
      dispatch(cartActions.removeCart({ artwork: artworkToRemove }));
    }
  };

  const closeModal = () => {
    onClose();
  };

  const order = useCallback(() => {
    if (cartArtworks.length === 0) onClose();
    else {
      ApiServer.post<BasicResponse<ArtworkOrder>>('/order', {
        items: cartArtworks.map((option) => option.artworkId),
      })
        .then(({ data: { data } }) => {
          console.log('purchase data', data);
          navigate(`/order/${data.orderId}`);
          onClose();
        })
        .catch((e) => {
          console.log('error fetching cart confirm: ', e);
        });
    }
  }, [cartArtworks]);

  if (loading) {
    return <Typography>{t('w.common.info.loading')}...</Typography>;
  }

  if (error) {
    return (
      <Typography>
        :{t('w.common.info.error')}
        {error}
      </Typography>
    );
  }

  const totalPrice = cartArtworks.reduce(
    (total, item) => total + item.price.price,
    0
  );

  const cartEmptyPage = (
    <>
      <Box
        display="flex"
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          height: '50vh',
          overflow: 'auto',
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ margin: '1rem' }}
        >
          {count === 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography marginTop={'13rem'}>
                {t('s.cart-modal.info.empty')}
              </Typography>
            </Box>
          ) : (
            cartArtworks.map((artworkItemCart) => (
              <Box
                key={artworkItemCart.id}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ margin: '0.5rem' }}
              >
                <CartModalItem
                  id={artworkItemCart.id}
                  price={artworkItemCart.price}
                  artist={artworkItemCart.artist}
                  title={artworkItemCart.title}
                  material={artworkItemCart.material}
                  width={artworkItemCart.width}
                  height={artworkItemCart.height}
                  depth={artworkItemCart.depth}
                  artworkId={artworkItemCart.artworkId}
                  imgPath={artworkItemCart.medias.slice(-1)[0].media.path}
                  onDelete={handleDeleteItem}
                />
              </Box>
            ))
          )}
        </Box>
      </Box>
      <Box sx={{ paddingX: '1rem', pt: '1rem' }}>
        <Typography variant="h6">{`${t(
          'w.common.info.total-price'
        )}: ￦${totalPrice}`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Link to={'/cart'}>
          <Button
            sx={{
              mt: '1rem',
              borderRadius: '0',
            }}
            onClick={closeModal}
            variant={'outlined'}
          >
            {t('s.cart-modal.func.to-cart')}
          </Button>
        </Link>
        {count > 0 && (
          <Button
            sx={{
              mt: '1rem',
              borderRadius: '0',
              ml: '1rem',
            }}
            onClick={order}
            variant={'contained'}
          >
            {t('s.cart-modal.func.purchase')}
          </Button>
        )}
      </Box>
    </>
  );

  return (
    <Modal
      open={isCartOpen}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Paper
        sx={{ bgColor: 'background.paper', height: '64vh', width: '40rem' }}
      >
        {cartEmptyPage}
      </Paper>
    </Modal>
  );
};

export default CartModal;
