import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum FilterCategory {
  CATEGORY,
  PRICE,
  ARTIST,
  KEYWORD
}

export type FilterType = {
  name: string;
  id: number | string;
  content: string;
  category: FilterCategory;
};

type StateType = {
  filters: FilterType[];
};

export type FilterPayload = {
  filter: FilterType;
};

type RemoveFilterPayload = {
  id: number | string;
  category: FilterCategory;
};

const initialFilterState: StateType = { filters: [] };

export const filterSlice = createSlice({
  name: "filter",
  initialState: initialFilterState,
  reducers: {
    addFilter(state, action: PayloadAction<FilterPayload>) {
      const { filter } = action.payload;

      const existingFilterIndex = state.filters.findIndex(
          (f) => f.id === filter.id && f.category === filter.category
      );

      const existFirst =
          state.filters.length > 0 &&
          state.filters[0].id === filter.id &&
          state.filters[0].category === filter.category;

      const existingPriceFilterIndex = state.filters.findIndex(
          (f) => filter.category === FilterCategory.PRICE && f.category === FilterCategory.PRICE
      );

      const existingKeywordFilterIndex = state.filters.findIndex(
          (f) => filter.category === FilterCategory.KEYWORD && f.category === FilterCategory.KEYWORD
      );

      if (existingFilterIndex !== -1) {
        console.log("The selected item is already in the filters.");
        console.log("Existing index: ", existingFilterIndex);
      } else {
        console.log("Existing first: ", existFirst);
        if (existingPriceFilterIndex !== -1 && filter.category === FilterCategory.PRICE) {
          state.filters.splice(existingPriceFilterIndex, 1, filter);
        } else if (existingKeywordFilterIndex !== -1 && filter.category === FilterCategory.KEYWORD) {
          state.filters.splice(existingKeywordFilterIndex, 1, filter);
        } else if (!existFirst) {
          state.filters.push(filter);
        }
      }
    },
    removeFilter(state, action: PayloadAction<RemoveFilterPayload>) {
      const { id, category } = action.payload;
      state.filters = state.filters.filter(
          (filter) => !(filter.id === id && filter.category === category)
      );
    },
    removeAll(state) {
      state.filters = [];
    },
  },
});

export const filterActions = filterSlice.actions;
export default filterSlice.reducer;
