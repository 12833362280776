import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  remember: false,
};

type RememberPayload = {
  remember: boolean;
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    login(state, action: PayloadAction<RememberPayload>) {
      state.isAuthenticated = true;
      state.remember = action.payload.remember;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.remember = false;
      if (state.remember) {
        sessionStorage.clear();
      } else {
        localStorage.clear();
        sessionStorage.clear();
      }
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
