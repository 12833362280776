import { useSelector } from 'react-redux';
import { RootState } from 'store/redux';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserInformation = () => {
  const userInfo = useSelector((state: RootState) => state.user.user);
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('t.user.info')}
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Typography variant="body1">
          {t(`t.user.name`)}: {`${userInfo?.firstName} ${userInfo?.lastName}`}
        </Typography>
        <Typography variant="body1">
          {t(`t.user.email`)}: {userInfo?.email}
        </Typography>
        <Typography variant="body1">
          {t(`t.user.birthday`)}: {userInfo?.birthday}
        </Typography>
      </Box>
    </>
  );
};

export default UserInformation;
