import { TextField, TextFieldProps } from "@mui/material";
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

function FormTextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: TextFieldProps & UseControllerProps<TFieldValues, TName>) {
  const textFieldProps = props as TextFieldProps;
  const {
    field,
    fieldState: { error },
  } = useController(props as UseControllerProps);

  return (
    <TextField
      {...textFieldProps}
      {...field}
      error={!!error}
      value={field.value ?? ""}
      helperText={!!error && error.message}
    />
  );
}

export default FormTextField;
