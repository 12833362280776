import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import ArtWorkItem from '../models/artwork/ArtWorkItem';
import { ApiServer } from '../utils/axios';
import ArtworkItemCart from '../models/artwork/ArtworkItemCart';
import BasicResponse from '../models/common/BasicResponse';

type initialCartStateType = {
  count: number;
  cartArtworks: ArtWorkItem[];
  loading: boolean;
  error: string | null;
};

const initialCartState: initialCartStateType = {
  count: 0,
  cartArtworks: [],
  loading: false,
  error: null,
};

type CartPayload = {
  artwork: ArtWorkItem;
};

type CartPayloads = {
  artworks: ArtWorkItem[];
};

export const fetchCartItemsFromAPI = async (): Promise<ArtWorkItem[]> => {
  const response = await ApiServer.get<BasicResponse<ArtworkItemCart[]>>(
    '/artwork/shopping-cart/all'
  );
  return response.data.data.map((item) => item.artworkItem);
};

export const fetchCartItems = createAsyncThunk(
  'cart/fetchCartItems',
  async () => {
    return await fetchCartItemsFromAPI();
  }
);

export const fetchAndSetAllCartItems = createAsyncThunk(
  'cart/fetchAndSetAllCartItems',
  async (_, { dispatch }) => {
    const artworks = await fetchCartItemsFromAPI();
    dispatch(cartSlice.actions.setAllItemsInCart({ artworks }));
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    addCart(state, action: PayloadAction<CartPayload>) {
      const existingCartIndex = state.cartArtworks.findIndex(
        (artwork) => artwork.id === action.payload.artwork.id
      );
      if (existingCartIndex === -1) {
        state.cartArtworks.push(action.payload.artwork);
        state.count++;
      }
    },
    removeCart(state, action: PayloadAction<CartPayload>) {
      state.cartArtworks = state.cartArtworks.filter(
        (artwork) => artwork.id !== action.payload.artwork.id
      );
      state.count = state.cartArtworks.length;
    },
    setAllItemsInCart(state, action: PayloadAction<CartPayloads>) {
      state.cartArtworks = action.payload.artworks;
      state.count = action.payload.artworks.length;
    },
    setUserCartCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.loading = false;
        state.cartArtworks = action.payload;
        state.count = action.payload.length;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch cart items';
      });
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
