import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { FilterCategory, FilterType } from '../../store/filter-redux';
import { useDispatch } from 'react-redux';
import { filterActions } from '../../store/filter-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type CategoryProps = {
  type: string;
};

const Category = ({ type }: CategoryProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState<number | null | string>(null);
  const { t } = useTranslation();

  const categoriesByMedia: FilterType[] = [
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.water-color'),
      id: 1,
      content: 'water-color',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.oil-painting'),
      id: 2,
      content: 'oil-painting',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.acrylic'),
      id: 3,
      content: 'acrylic',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.mixed-media'),
      id: 4,
      content: 'mixed-media',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.ceramics'),
      id: 5,
      content: 'ceramics',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.sculptures'),
      id: 6,
      content: 'sculptures',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.photography'),
      id: 7,
      content: 'photography',
    },
    {
      category: FilterCategory.CATEGORY,
      name: t('w.common.category.others'),
      id: 8,
      content: 'others',
    },
  ];

  const categoriesMonetary: FilterType[] = [
    {
      name: t('w.filter.direct.under-500'),
      id: 1,
      content: 'under-500',
      category: FilterCategory.PRICE,
    },
    {
      name: t('w.filter.direct.btwn-500-and-1000'),
      id: 2,
      content: 'btwn-500-and-1000',
      category: FilterCategory.PRICE,
    },
    {
      name: t('w.filter.direct.btwn-1000-and-2000'),
      id: 3,
      content: 'btwn-1000-and-2000',
      category: FilterCategory.PRICE,
    },
    {
      name: t('w.filter.direct.over-2000'),
      id: 4,
      content: 'over-2000',
      category: FilterCategory.PRICE,
    },
  ];

  const applyFilterHandler = (event: any) => {
    console.log(event);

    dispatch(filterActions.removeAll());

    let filteredFilter = categoriesByMedia.filter(
      (item) =>
        item.name.toLowerCase() === event.target.textContent.toLowerCase()
    );

    if (filteredFilter.length === 0) {
      filteredFilter = categoriesMonetary.filter(
         (item) => item.name.toLowerCase() === event.target.textContent.toLowerCase()
      );
    }

    if (filteredFilter.length > 0) {
      dispatch(filterActions.addFilter({ filter: filteredFilter[0] }));
      setSelectedFilter(filteredFilter[0].id);
      navigate('/merchandises');
    } else {
      // 필터가 없는 경우 처리
      console.error('일치하는 필터가 없습니다');
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: 'center', mt: '2rem', mb: '4rem' }}
    >
      {type === 'monetary'
        ? categoriesMonetary.map((item: FilterType) => (
            <Grid item key={item.id}>
              <Button
                variant="outlined"
                sx={{
                  width: '10rem',
                  height: '3rem',
                  borderRadius: '0',
                  borderTopColor: 'black',
                  borderBottomColor: 'black',
                  borderLeftColor: 'black',
                  borderRightColor: 'black',
                  color: 'black',
                  borderColor: selectedFilter === item.id ? 'black' : 'grey',
                  '&:hover': {
                    borderColor: 'grey',
                    color: 'grey',
                  },
                  backgroundColor:
                    selectedFilter === item.id ? '#f0f0f0' : 'white',
                }}
                onClick={applyFilterHandler}
              >
                {t(`w.filter.direct.${item.content}`)}
              </Button>
            </Grid>
          ))
        : categoriesByMedia.map((item: FilterType) => (
            <Grid item key={item.name}>
              <Link to={'/merchandises'}>
                <Button
                  variant="outlined"
                  sx={{
                    width: '10rem',
                    height: '3rem',
                    borderRadius: '0',
                    borderTopColor: 'black',
                    borderBottomColor: 'black',
                    borderLeftColor: 'black',
                    borderRightColor: 'black',
                    color: 'black',
                    '&:hover': {
                      borderColor: 'grey',
                      color: 'grey',
                    },
                    borderColor: selectedFilter === item.id ? 'black' : 'grey',
                    backgroundColor:
                      selectedFilter === item.id ? '#f0f0f0' : 'white',
                  }}
                  onClick={applyFilterHandler}
                >
                  {t(`w.common.category.${item.content}`)}
                </Button>
              </Link>
            </Grid>
          ))}
    </Grid>
  );
};

export default Category;
