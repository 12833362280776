import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialLangState = {
  lang: 'en',
};

type langPayload = {
  langId: number;
};

const langSlice = createSlice({
  name: 'language',
  initialState: initialLangState,
  reducers: {
    setLanguage(state, action: PayloadAction<number>) {
      if (action.payload === 0) {
        state.lang = 'en';
      } else if (action.payload === 1) {
        state.lang = 'ko';
      }
    },
  },
});

export const langActions = langSlice.actions;
export default langSlice.reducer;
