import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/redux';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Paper,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { ApiServer } from '../../utils/axios';
import FormTextField from '../../models/common/FormTextField';
import { BasicResponse } from '../../models/common';
import useCDNResource from '../../utils/useCDNResource';
import { UserToken } from '../../models/user';
import { authActions } from '../../store/auth-redux';
import { tokenActions } from '../../store/auth-token-redux';
import styles from './auth.module.css';
import useMountEffect from '../../hooks/useMountEffect';
import { useTranslation } from 'react-i18next';

type FormValue = {
  email: string;
  password: string;
  remember: boolean;
};

function LoginPage() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isInCorrect, setIsInCorrect] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCDNResource } = useCDNResource();
  const [errorMessage, setErrorMessage] = useState('');
  const { control, handleSubmit, watch } = useForm<FormValue>({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });
  const { t } = useTranslation();

  const emailValue = watch('email');
  const passwordValue = watch('password');

  const auth = useSelector((state: RootState) => state.auth);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };
  useMountEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setErrorMessage('');
  }, [emailValue, passwordValue]);

  const onSubmit: SubmitHandler<FormValue> = ({
    email,
    password,
    remember,
  }) => {
    ApiServer.post<BasicResponse<UserToken>>('/auth/login', { email, password })
      .then(({ data: { data } }) => {
        navigate('/');
        const accessToken = `${data.grantType} ${data.accessToken}`;
        const refreshToken = `${data.grantType} ${data.refreshToken}`;
        dispatch(authActions.login({ remember }));
        dispatch(tokenActions.setAccessToken(accessToken));
        dispatch(tokenActions.setRefreshToken(refreshToken));
        if (remember) {
          localStorage.setItem('access-token', accessToken);
          localStorage.setItem('refresh-token', refreshToken);
          localStorage.setItem('remember', 'true');
          auth.remember = true;
        } else {
          sessionStorage.setItem('access-token', accessToken);
          sessionStorage.setItem('refresh-token', refreshToken);
          localStorage.removeItem('remember');
          auth.remember = false;
        }
      })
      .catch(({ response }: AxiosError) => {
        if (response) {
          const { data } = response;
          console.log(data);
          if (data && typeof data === 'string') {
            setErrorMessage(data);
          }
          setIsInCorrect(true);
        }
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 0,
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          maxWidth: '400px',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 'none', // Remove elevation
        }}
      >
        <Box
          component="img"
          width="60%"
          src={getCDNResource('/logo/horizontal_logo.webp')}
          alt="Logo"
          sx={{ mb: '2rem' }}
        />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          gap={2}
          width="100%"
        >
          <FormTextField
            name="email"
            type="email"
            label={`${t(`w.common.info.email`)}`}
            autoComplete="email"
            aria-describedby="email"
            control={control}
            fullWidth
            required
            autoFocus
          />
          <FormTextField
            name="password"
            type="password"
            label={`${t(`w.common.info.password`)}`}
            aria-describedby="password"
            control={control}
            fullWidth
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleCheckboxChange}
                value="remember"
                color="primary"
              />
            }
            label={`${t(`w.login.func.remember-me`)}`}
          />
          <Typography>
            {t(`s.login.nav-to.not-registered`)}{' '}
            <Link to={'/signup'} className={styles.customLink}>
              {t(`w.login.func.signup`)}
            </Link>
          </Typography>
          <Button type="submit" fullWidth variant="contained">
            {t(`w.login.func.submit`)}
          </Button>
          <FormHelperText sx={{ minHeight: '20px' }} error>
            {errorMessage}
          </FormHelperText>
            <Link to={"/email-input"}>
            <FormHelperText>
                {isInCorrect && "비밀번호 찾기"}
            </FormHelperText>
            </Link>
        </Box>
      </Paper>
    </Box>
  );
}

export default LoginPage;
