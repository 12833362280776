import { useState, useEffect, useCallback } from 'react';
import { ApiServer } from 'utils/axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/redux';
import useMountEffect from './useMountEffect';
import { cartActions } from 'store/cart-redux';
import ArtWorkItem from 'models/artwork/ArtWorkItem';

const useCart = (artworkId: number) => {
  const [isInCart, setIsInCart] = useState<boolean | null>(null);
  const [error, setError] = useState<any>(null);
  const [cartLoading, setCartLoading] = useState(false);
  const [artwork, setArtwork] = useState<ArtWorkItem | null>(null);
  const userId = useSelector((state: RootState) => state.user.user?.id);
  const dispatch = useDispatch();

  // 카트 초기 상태 가져오기
  const fetchInitialCartState = useCallback(
    async (artworkId: number, userId: number) => {
      setCartLoading(true);
      return ApiServer.get(`/artwork/shopping-cart/${artworkId}`, {
        params: { userId },
      })
        .then(({ data: { data } }) => {
          console.log('is in cart status ', data);
          if (data) return true;
          else return false;
        })
        .finally(() => {
          setCartLoading(false);
        });
    },
    []
  );

  // Artwork 데이터를 가져오는 함수
  const fetchArtwork = useCallback(async (artworkId: number) => {
    return ApiServer.get(`/artwork/item/${artworkId}`)
      .then(({ data: { data } }) => {
        setArtwork(data);
      })
      .catch((error) => {
        console.error(`Error fetching artwork data: `, error);
        setError(error);
      });
  }, []);

  useMountEffect(() => {
    console.log('user id', userId);
    if (userId) {
      fetchInitialCartState(artworkId, userId).then((isInCart) => {
        console.log('is in cart', isInCart);
        setIsInCart(isInCart);
      });
      fetchArtwork(artworkId);
    }
  }, [userId, fetchInitialCartState, artworkId, fetchArtwork]);

  // 카트 상태를 토글하는 함수
  const toggleCart = () => {
    const endpoint = isInCart
      ? `/artwork/shopping-cart/${artworkId}`
      : `/artwork/shopping-cart`;
    setCartLoading(true);

    if (isInCart) {
      ApiServer.delete(endpoint)
        .then(() => {
          console.log(`successfully removed ${artworkId} from cart.`);
          if (artwork) {
            dispatch(cartActions.removeCart({ artwork }));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error('BAD REQUEST');
          } else {
            console.error(`Error removing artwork from cart: `, error);
          }
          setError(error);
        })
        .finally(() => {
          setIsInCart((prevIsInCart) => !prevIsInCart);
          setCartLoading(false);
        });
    } else {
      ApiServer.post(endpoint, {
        artworkItemId: artworkId,
        size: 1,
        shippingMethodId: 1, //TODO: 일단 1로
      })
        .then(() => {
          console.log(`successfully added ${artworkId} to cart.`);
          if (artwork) {
            dispatch(cartActions.addCart({ artwork }));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error('BAD REQUEST');
          } else {
            console.error(`Error adding artwork to cart: `, error);
          }
          setError(error);
        })
        .finally(() => {
          setIsInCart((prevIsInCart) => !prevIsInCart);
          setCartLoading(false);
        });
    }
  };

  return { isInCart, toggleCart, error, cartLoading };
};

export default useCart;
