import React, { Suspense, useCallback, useEffect, useState } from 'react';
import usePayment from './hooks/usePayment';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, lightGreen } from '@mui/material/colors';
import './App.css';
import ExhibitionPage from './pages/ExhibitionPage';
import SliderSection from './components/sliders/SliderSection';
import CategoryMonetaryPage from './pages/categoryPage/CategoryMonetaryPage';
import CategoryNonMonetaryPage from './pages/categoryPage/CategoryNonMonetaryPage';
import CuratorBigImgSection from './components/curatorBigImg/CuratorBigImgSection';
import HomePageImages from './components/Images/HomePageImages';
import MerchPage from './pages/MerchPage';
import MapsPage from './pages/MapsPage';
import ArtistPage from './pages/artistPage/ArtistPage';
import ArtistIndividualPage from './components/artist/ArtistIndividualPage';
import RootLayout from './pages/layout/RootLayout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import FilterProvider from './store/filterProvider';
import ArtworkIndividualPage from './pages/artworkPage/ArtworkIndividualPage';
import { loader as ArtWorkEachLoader } from './utils/loaders/ArtWorkEachLoader';
import LoginPage from './pages/authentication/LoginPage';
import EmailInput from "./pages/authentication/EmailInput";
import MyPage from './pages/MyPage';
import CartPage from './pages/cart/CartPage';
import CartModal from './components/cart/CartModal';
import { ApiServer } from './utils/axios';
import { BasicResponse } from './models/common';
import { User } from './models/user';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'store/user-redux';
import { cartActions, fetchAndSetAllCartItems } from './store/cart-redux';
import { authActions } from 'store/auth-redux';
import useMountEffect from './hooks/useMountEffect';
import { RootState } from './store/redux';
import SignUpPage from './pages/authentication/SignUpPage';
import MyEditPage from 'pages/MyEditPage';
import { Root } from 'react-dom/client';
import ArtFairPage from 'pages/ArtFairPage';
import OrderConfirmPage from 'pages/order/OrderConfirmPage';
import { access } from 'fs';
import { tokenActions } from 'store/auth-token-redux';
import { AppDispatch } from './store/redux';
import { fetchCartItemsFromAPI } from './store/cart-redux';
import ViewAllArtworks from 'pages/artworkPage/ViewAllFavoriteArtworks';
import ResetPassword from "./pages/authentication/ResetPassword";
import OrderResultPage from "./pages/order/OrderResultPage";

export const outerTheme = createTheme({
  /** for more: https://mui.com/material-ui/customization/palette/
   * primary - for primary interface elements.
   **secondary - for secondary interface elements.
   **error - for elements that the user should be made aware of.
   **warning - for potentially dangerous actions or important messages.
   **info - for highlighting neutral information.
   **success - for indicating the successful completion of an action that the user trigger
   */
  typography: {
    fontFamily: [
      'Pretendard',
      'Libre Baskerville', // TODO: replace with the desired code
      'monospace',
    ].join(','),
  },
  palette: {
    /**
     * Palette colors are represented by four tokens:
     * main: The main shade of the color
     * light: A lighter shade of main
     * dark: A darker shade of main
     * contrastText: Text color, intended to contrast with main
     */
    primary: {
      main: grey[300],
    },
    secondary: {
      main: grey[700],
    },
    success: {
      main: lightGreen[700],
    },
  },
});

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { initialize } = usePayment();
  const dispatch = useDispatch<AppDispatch>();
  const { user, accessToken, isAuthenticated } = useSelector(
    (state: RootState) => ({
      user: state.user,
      accessToken: state.token.AccessToken,
      isAuthenticated: state.auth.isAuthenticated,
    })
  );
  // const accessToken =
  //   localStorage.getItem('access-token') ||
  //   sessionStorage.getItem('access-token');

  useMountEffect(() => {
    const remember = localStorage.getItem('remember');
    if (remember) {
      const accessToken = localStorage.getItem('access-token');
      if (accessToken) dispatch(tokenActions.setAccessToken(accessToken));
    } else {
      const accessToken = sessionStorage.getItem('access-token');
      if (accessToken) dispatch(tokenActions.setAccessToken(accessToken));
    }
  });

  useEffect(() => {
    const isAuthenticated = Boolean(accessToken && user);
    dispatch(authActions.setIsAuthenticated(isAuthenticated));

    (async () => {
      const artworks = await fetchCartItemsFromAPI();
      dispatch(cartActions.setAllItemsInCart({ artworks }));
    })();
  }, [accessToken, user, dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const refreshUserInfo = useCallback(
    (token: string | null) => {
      if (token) {
        ApiServer.get<BasicResponse<User>>('/user/me', {
          headers: { Authorization: token },
        })
          .then(({ data: { data } }) => {
            console.log('user me', data);
            dispatch(userActions.setUser(data)); // Dispatch action to set user data
          })
          .catch((error) => {
            console.error('Failed to fetch user info:', error);
            dispatch(userActions.setUser(undefined)); // Dispatch action to clear user data as null
          });
      } else {
        dispatch(userActions.setUser(undefined)); // Dispatch action to clear user data as null
      }
    },
    [dispatch] // Include dispatch in the dependency array
  );

  const refreshUserCartInfo = useCallback(
    (token: string | null) => {
      ApiServer.get<BasicResponse<number>>('/artwork/shopping-cart/count', {
        headers: { Authorization: token },
      }).then(({ data: { data } }) => {
        dispatch(cartActions.setUserCartCount(data));
      });
    },
    [dispatch]
  );

  useMountEffect(() => {
    console.log('ACCESS TOKEN', accessToken);
    if (accessToken) {
      refreshUserInfo(accessToken);
      refreshUserCartInfo(accessToken);
    }
  }, [accessToken]);

  const openMenuHandler = () => {
    setIsMenuOpen(true);
  };

  const closeMenuHandler = () => {
    setIsMenuOpen(false);
  };

  const openCartHandler = () => {
    setIsCartOpen(true);
  };

  const closeCartHandler = () => {
    setIsCartOpen(false);
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <RootLayout
          openMenu={openMenuHandler}
          openCart={openCartHandler}
          isMenuOpen={isMenuOpen}
          isCartOpen={isCartOpen}
          closeMenu={closeMenuHandler}
          closeCart={closeCartHandler}
          onClose={closeMenuHandler}
        />
      ),
      children: [
        {
          index: true,
          element: [
            <SliderSection />,
            <CategoryMonetaryPage />,
            <HomePageImages />,
            <CategoryNonMonetaryPage />,
            <CuratorBigImgSection />,
            isCartOpen && (
              <CartModal
                isCartOpen={isCartOpen}
                onClose={closeCartHandler}
                onOpen={openCartHandler}
              />
            ),
          ],
        },
        { path: 'exhibition', element: <ExhibitionPage /> },
        { path: 'artFair', element: <ArtFairPage /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'signup', element: <SignUpPage /> },
        {path: 'email-input', element: <EmailInput/>},
        {path: 'reset-password', element: <ResetPassword/>},
        { path: 'myPage', element: <MyPage /> },
        { path: 'myPage/edit', element: <MyEditPage /> },
        { path: 'myPage/favorites', element: <ViewAllArtworks /> },
        { path: 'cart', element: <CartPage /> },
        {
          path: 'order',
          children: [
            {
              path: ':orderId',
              id: 'confirmation-page',
              element: <OrderConfirmPage />,
            },
            {
              path: "result",
              id: 'result-page',
              element: <OrderResultPage/>
            }
          ],
        },
        {
          path: 'merchandises',
          children: [
            { index: true, element: <MerchPage /> },
            {
              path: ':artworkId',
              id: 'individual-artwork',
              element: <ArtworkIndividualPage />,
              loader: ArtWorkEachLoader,
            },
          ],
        },
        { path: 'visit', element: <MapsPage /> },
        {
          path: 'artists',
          children: [
            { index: true, element: <ArtistPage /> },
            { path: ':id', element: <ArtistIndividualPage /> },
          ],
        },
      ],
    },
  ]);

  return (
    <FilterProvider>
      <ThemeProvider theme={outerTheme}>
        <RouterProvider router={router}></RouterProvider>
      </ThemeProvider>
    </FilterProvider>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
