import React, { useState, ChangeEvent, FormEvent } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { useTranslation } from 'react-i18next';

interface AddressData {
  address: string;
  zonecode: string;
}

interface DaumAddressSearchProps {
  onAddressSelected: (address: string) => void;
}

const DaumAddressSearch: React.FC<DaumAddressSearchProps> = ({
  onAddressSelected,
}) => {
  const [zonecode, setZonecode] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [detailedAddress, setDetailedAddress] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const themeObj = {
    bgColor: '#F7F7F7',
    pageBgColor: '#F7F7F7',
    postcodeTextColor: '#444',
    emphTextColor: '#222',
  };

  const postCodeStyle: React.CSSProperties = {
    width: '100%',
    height: '480px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  };

  const completeHandler = (data: AddressData) => {
    const { address, zonecode } = data;
    setZonecode(zonecode);
    setAddress(address);
    setIsOpen(false);
  };

  const closeHandler = (state: 'FORCE_CLOSE' | 'COMPLETE_CLOSE') => {
    if (state === 'FORCE_CLOSE' || state === 'COMPLETE_CLOSE') {
      setIsOpen(false);
    }
  };

  const toggleHandler = () => {
    setIsOpen((prevOpenState) => !prevOpenState);
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDetailedAddress(event.target.value);
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const fullAddress = `${address} ${detailedAddress}`;
    onAddressSelected(fullAddress);
  };

  return (
    <div style={{ marginTop: '1rem' }}>
      <form onSubmit={submitHandler}>
        <div style={{ marginBottom: '0.5rem' }}>
          <strong style={{ fontSize: '1.2rem', color: '#444' }}>
            {t(`w.common.func.find-address`)}
          </strong>
        </div>
        <div style={{ display: 'flex', marginBottom: '0.5rem' }}>
          <input
            type="text"
            value={zonecode}
            readOnly
            placeholder={t(`w.common.info.zipcode`)}
            style={{
              flex: '2',
              marginRight: '0.5rem',
              padding: '0.5rem',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxSizing: 'border-box',
            }}
          />
          <button
            type="button"
            onClick={toggleHandler}
            style={{
              flex: '1',
              padding: '0.5rem',
              border: 'none',
              borderRadius: '4px',
              backgroundColor: '#888',
              color: '#fff',
              cursor: 'pointer',
              boxSizing: 'border-box',
            }}
          >
            {t(`w.common.func.find-address`)}
          </button>
        </div>
        {isOpen && (
          <div style={{ marginBottom: '0.5rem' }}>
            <DaumPostcode
              theme={themeObj}
              style={postCodeStyle}
              onComplete={completeHandler}
              onClose={closeHandler}
            />
          </div>
        )}
        <input
          type="text"
          value={address}
          readOnly
          placeholder={t(`w.common.info.street-address`)}
          style={{
            width: '100%',
            marginBottom: '0.5rem',
            padding: '0.5rem',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
        <input
          type="text"
          value={detailedAddress}
          onChange={inputChangeHandler}
          placeholder={t(`w.common.info.detailed-address`)}
          style={{
            width: '100%',
            marginBottom: '0.5rem',
            padding: '0.5rem',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxSizing: 'border-box',
          }}
        />
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '0.5rem',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#888',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          {t(`w.common.func.submit`)}
        </button>
      </form>
    </div>
  );
};

export default DaumAddressSearch;
