import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useCallback,
  useMemo,
} from 'react';
import GlobalAddress from 'models/address/GlobalAddress';
import axios from 'axios';
import countries from '../../assets/countries.json';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  SelectChangeEvent,
  TextField,
  FormHelperText,
  Button,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';

interface GlobalAddressFinderProps {
  onAddressSelected: (address: string) => void;
}

const GlobalAddressFinder: React.FC<GlobalAddressFinderProps> = ({
  onAddressSelected,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cntryCode, setCntryCode] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [detailedAddress, setDetailedAddress] = useState<string>('');
  const [addressData, setAddressData] = useState<GlobalAddress | null>(null);

  const addySearch = useCallback(
    debounce((zipCode: string, cntryCode: string) => {
      setLoading(true);
      axios({
        url: 'https://global-address.p.rapidapi.com/V3/WEB/GlobalAddress/doGlobalAddress',
        method: 'get',
        params: {
          ctry: cntryCode,
          postal: zipCode,
          format: 'json',
          DeliveryLines: 'off',
        },
        headers: {
          'x-rapidapi-host': 'global-address.p.rapidapi.com',
          'x-rapidapi-key':
            'c4f1143c12msh07157bb8e63ba39p140fb3jsned71ea3807d0',
        },
      })
        .then((res: any) => {
          setLoading(false);
          if (!res.data || res.data.Records[0].AddressLine1 === '') {
            alert('No results were found for your search.');
          }
          setAddressData(res?.data);
          console.log(res?.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(
            `Error occurred getting the address for the zipcode: `,
            err
          );
        });
    }, 300),
    []
  );

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    setCntryCode(event.target.value as string);
  };

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZipCode(event.target.value);
    addySearch(event.target.value, cntryCode);
  };

  const handleDetailedAddressChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDetailedAddress(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (addressData) {
      const fullAddress = `${detailedAddress}, ${addressData.Records[0].FormattedAddress}`;
      onAddressSelected(fullAddress);
    }
  };

  const countryOptions = useMemo(
    () =>
      countries.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          {country.name}
        </MenuItem>
      )),
    []
  );

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel id="country-select-label">Country</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            value={cntryCode}
            onChange={handleCountryChange}
            label="Country"
          >
            {countryOptions}
          </Select>
        </FormControl>

        {cntryCode && (
          <Box>
            <TextField
              id="zip-code"
              label="Zipcode"
              autoComplete="zipcode"
              fullWidth
              value={zipCode}
              onChange={handleZipCodeChange}
              margin="normal"
            />
            <FormHelperText id="helper-text">
              Please enter the address' zip code.
            </FormHelperText>

            {loading && <CircularProgress sx={{ mt: '1rem' }} />}

            {addressData && (
              <Box sx={{ mt: '1rem' }}>
                <TextField
                  id="address"
                  label="address"
                  fullWidth
                  value={addressData.Records[0].AddressLine1}
                />
                <TextField
                  id="detailed-address"
                  label="Detailed Address"
                  autoComplete="detailed-address"
                  fullWidth
                  value={detailedAddress}
                  onChange={handleDetailedAddressChange}
                  margin="normal"
                />
                <FormHelperText id="detailed-address-helper-text">
                  Please enter the detailed address.
                </FormHelperText>

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{ mt: '1rem' }}
                >
                  Submit Address
                </Button>
              </Box>
            )}
          </Box>
        )}
      </form>
    </div>
  );
};

export default GlobalAddressFinder;
