import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';
import en from './en/en.json';
import ko from './ko/ko.json';

i18n
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ko: {
        translation: ko,
      },
    },
    fallbackLng: 'en',
    debug: true,
    keySeparator: '.', // dot notation for nested keys
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
