import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/header/Header";
import SideMenu from "../../components/menu/sideMenu/SideMenu";
import CartModal from "../../components/cart/CartModal";
import Footer from "../../components/footer/Footer";
import VideoBackground from "../videoBackground/VideoBackground";
import useMountEffect from "../../hooks/useMountEffect";
import { Outlet } from "react-router-dom";

type RootLayoutProps = {
  openMenu: () => void;
  openCart: () => void;
  isMenuOpen: boolean;
  isCartOpen: boolean;
  closeMenu: () => void;
  closeCart: () => void;
  onClose: () => void;
};

const RootLayout = ({
  openMenu,
  openCart,
  isMenuOpen,
  isCartOpen,
  closeMenu,
  closeCart,
}: RootLayoutProps) => {
  const [showVideo, setShowVideo] = useState(true);

  useMountEffect(() => {
    const isVideoClicked = localStorage.getItem("pictureClicked");
    if (isVideoClicked) {
      setShowVideo(false);
    }
  }, []);

  const handleVideoClick = () => {
    localStorage.setItem("videoClicked", "true");
    setShowVideo(false);
  };

  if (!showVideo) {
    return (
      <Box>
        <Header openCart={openCart} openMenu={openMenu} />
        <SideMenu
          openCart={openCart}
          closeCart={closeCart}
          isOpen={isMenuOpen}
          closeMenu={closeMenu}
        />
        {isCartOpen && (
          <CartModal
            isCartOpen={isCartOpen}
            onClose={closeCart}
            onOpen={openCart}
          />
        )}
        <Outlet />
        <Footer />
      </Box>
    );
  } else {
    return <VideoBackground backgroundClickHandler={handleVideoClick} />;
  }
};

export default RootLayout;
