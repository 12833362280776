import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import useCDNResource from '../../utils/useCDNResource';
import ArtworkItemCart from '../../models/artwork/ArtworkItemCart';

type CartItemProps = {
  option: ArtworkItemCart & { checked: boolean }; // checked 속성을 포함하도록 수정
  isCartEmpty: boolean;
  handleOptionChange: (
    id: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CartItem = ({
  option,
  isCartEmpty,
  handleOptionChange,
}: CartItemProps) => {
  const { getCDNResource } = useCDNResource();

  if (isCartEmpty) {
    return null;
  }

  return (
    <Box
      sx={{
        paddingY: '2rem',
        mt: '1rem',
        borderBottom: 1,
        borderColor: 'lightgrey',
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          md={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          1
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={option.checked}
                onChange={handleOptionChange(option.id)}
              />
            }
            label=""
          />
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '7rem', display: 'flex', alignItems: 'center' }}>
              <img
                src={getCDNResource(option.artworkItem.medias[0]?.media.path)}
                alt={option.artworkItem.title}
                style={{ width: '100%' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                ml: '2rem',
              }}
            >
              <Box>
                <Typography>{option.artworkItem.title}</Typography>
              </Box>
              <Box>
                <Typography>
                  {option.artworkItem.artwork.artist.name}
                </Typography>
              </Box>
              <Box>
                <Typography>{option.artworkItem.material}</Typography>
              </Box>
              <Box>
                <Typography>{`W: ${option.artworkItem.width} x H: ${option.artworkItem.height} x D: ${option.artworkItem.depth}`}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          1
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`₩ ${option.artworkItem.price.price}`}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartItem;
