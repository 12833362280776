import { combineReducers, configureStore } from '@reduxjs/toolkit';
import filterReducer from './filter-redux';
import authReducer from './auth-redux';
import tokenReducer from './auth-token-redux';
import cartReducer from './cart-redux';
import userReducer from './user-redux';
import langReducer from './lang-redux';
import loadingReducer from './loading-redux';
import storageSession from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// Persist configuration for the root reducer
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['user'],
  whitelist: ['filter', 'auth', 'cart'],
};

// Persist configuration for the user reducer
const userPersistConfig = {
  key: 'user',
  storage: storageSession,
};

// Persist configuration for the lang reducer
const langPersistconfig = {
  key: 'lang',
  storage: storageSession,
};

// Combine the reducers
const rootReducer = combineReducers({
  loading: loadingReducer,
  filter: filterReducer,
  auth: authReducer,
  token: tokenReducer,
  cart: cartReducer,
  user: persistReducer(userPersistConfig, userReducer),
  lang: persistReducer(langPersistconfig, langReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

// Configure the store with the persisted reducer and middleware
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>; //for useSelector type
export type AppDispatch = typeof store.dispatch;
export default store;
export const persistor = persistStore(store);
