import {
    IMPPaymentMethod,
    IMPPgProvider,
    IMPRequestPayResponse,
} from "models/iamport";
import {ArtworkOrder} from "models/order";
import {User, UserAddress} from "models/user";

const usePayment = () => {
    const {IMP} = window;
    const pgProvider = IMPPgProvider.NICEPAY_V2;
    const paymentMethod = IMPPaymentMethod.CREDIT_CARD;
    const shopID = process.env.REACT_APP_SHOP_ID;
    const initialize = () => {
        if (shopID) {
            IMP?.init(shopID);
        }
    };
    const requestPayment = (
        order: ArtworkOrder,
        user: User,
        address: UserAddress,
        callback: (response: IMPRequestPayResponse) => void,
    ) => {
        const [firstItem] = order.items ?? [];
        const orderName =
            order.items.length === 1
                ? firstItem.artworkItem.title
                : `${firstItem.artworkItem.title} 등 ${order.items.length}건`;
        IMP?.request_pay(
            {
                pg: pgProvider.code,
                pay_method: paymentMethod.code,
                name: orderName,
                merchant_uid: order.orderId,
                amount: Number(order.price),
                buyer_tel: address.contact, // address 필요한 이유
                buyer_email: user.email,
                buyer_name: `${user.firstName}${user.lastName}`,
            },
            callback,
        );
    };

    return {initialize, requestPayment};
};

export default usePayment;
