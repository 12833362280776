import React from 'react';
import { Typography, Box } from '@mui/material';
import Category from '../../components/category/Category';
import { useTranslation } from 'react-i18next';

const CategoryMonetaryPage = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h4" sx={{ ml: '2rem', mt: '6rem' }}>
        {t(`t.main.category.shop-by-price`)}
      </Typography>
      <Category type="monetary" />
    </Box>
  );
};

export default CategoryMonetaryPage;
