import React from "react";
import { FilterType } from "./filterProvider";

const filterTypedArray: FilterType[] = [];
const FilterContext = React.createContext({
  filters: filterTypedArray,
  addFilter: (category: FilterType) => {},
  removeFilter: (category: FilterType) => {},
});

export default FilterContext;
