import React, { useReducer } from "react";
import FilterContext from "./filter-context";

type FilterProviderProps = {
  children: React.ReactNode;
};

export enum FilterCategory {
  CATEGORY,
  PRICE,
  ARTIST,
  KEYWORD
}

export type FilterType = {
  name: string;
  id: number | string;
  content: string;
  category: FilterCategory;
};

type StateType = {
  filters: FilterType[];
};

type ActionType = {
  type: string;
  filter: FilterType;
};

const defaultFilterState = { filters: [] };

const filterReducer = (state: StateType, action: ActionType): StateType => {
  if (action.type === "ADD") {
    const existingFilterIndex = state.filters.findIndex(
      (filter) =>
        filter.id === action.filter.id &&
        filter.category === action.filter.category,
    );
    const existingPriceFilterIndex = state.filters.findIndex(
      (filter) => action.filter.category === 1 && filter.category === 1,
    );
    const existingFilter = state.filters[existingFilterIndex];
    const existingPriceFilter = state.filters[existingPriceFilterIndex];

    let updatedFilters: FilterType[] = [];

    if (existingFilter) {
      updatedFilters = state.filters;
    } else {
      if (existingPriceFilter && action.filter.category === 1) {
        state.filters.splice(existingPriceFilterIndex, 1, action.filter);
        updatedFilters = state.filters;
      } else {
        updatedFilters = state.filters.concat(action.filter);
      }
    }
    return { filters: updatedFilters };
  } else if (action.type === "REMOVE") {
    const existingFilterIndex = state.filters.findIndex(
      (filter) =>
        filter.id === action.filter.id &&
        action.filter.category === filter.category,
    );

    const updatedFilters = state.filters.filter(
      (filterData: FilterType, idx: number) => idx !== existingFilterIndex,
    );
    return { filters: updatedFilters };
  }

  return defaultFilterState;
};
const FilterProvider = ({ children }: FilterProviderProps) => {
  const [filterState, dispatchFilterAction] = useReducer(
    filterReducer,
    defaultFilterState,
  );

  const addFilterHandler = (filter: FilterType) => {
    dispatchFilterAction({ type: "ADD", filter });
  };

  const removeFilterHandler = (filter: FilterType) => {
    dispatchFilterAction({ type: "REMOVE", filter });
  };

  const filterContext = {
    filters: filterState.filters,
    addFilter: addFilterHandler,
    removeFilter: removeFilterHandler,
  };

  return (
    <FilterContext.Provider value={filterContext}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
