// src/components/common/AnimatedWrapper.tsx

import React, { ReactNode } from 'react';
import { useSpring, animated } from '@react-spring/web';

type AnimatedWrapperProps = {
  children: ReactNode;
};

const AnimatedWrapper = ({ children }: AnimatedWrapperProps) => {
  const springProps = useSpring({
    from: { opacity: 0, marginTop: '8rem' },
    to: { opacity: 1, marginTop: '5rem' },
    config: { duration: 500 },
  });

  return <animated.div style={springProps}>{children}</animated.div>;
};

export default AnimatedWrapper;
