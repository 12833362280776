import { Box, Typography, Link, Paper, styled, IconButton } from '@mui/material';
import { Instagram, ChatBubbleOutline } from '@mui/icons-material';

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '50px auto',
  padding: '20px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: '8px',
}));

const ContactInfo = styled(Box)(({ theme }) => ({
  marginTop: '30px',
  paddingTop: '20px',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const PaymentGuide = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        결제 안내
      </Typography>
      <Typography variant="body1" paragraph>
        안녕하세요, 고객님.
      </Typography>
      <Typography variant="body1" paragraph>
        결제 금액이 2,000,000원을 초과할 경우, 갤러리 쏘마에 따로 문의해주셔야 합니다. 이는 고객님의 소중한 결제를 안전하게 처리하기 위해 마련된 정책입니다. 다음은 그 이유에 대한 자세한 설명입니다:
      </Typography>
      <Box component="ul">
        <Typography component="li" variant="body1" paragraph>
          <strong>안전한 거래 보장:</strong> 금액이 큰 거래일수록, 결제 과정에서 발생할 수 있는 다양한 리스크가 존재합니다. 이를 방지하기 위해 PG사(결제 대행사)는 고액 결제에 대해 보다 엄격한 보안 절차를 적용합니다. 고객님의 소중한 결제가 안전하게 처리될 수 있도록 하기 위함입니다.
        </Typography>
        <Typography component="li" variant="body1" paragraph>
          <strong>부정 결제 방지:</strong> PG사는 부정 결제를 방지하기 위해 일정 금액 이상의 거래에 대해 추가적인 검증 절차를 요구합니다. 이는 고객님의 결제 정보가 도용되거나 악용되는 것을 방지하는 데 중요한 역할을 합니다. 따라서 2,000,000원 이상의 결제는 갤러리 쏘마를 통해 직접 확인 절차를 거치는 것이 필요합니다.
        </Typography>
        <Typography component="li" variant="body1" paragraph>
          <strong>원활한 서비스 제공:</strong> 고액 결제는 때때로 결제 한도나 승인 절차 등에서 문제가 발생할 수 있습니다. 이러한 경우 고객님의 결제가 원활하게 이루어질 수 있도록 갤러리 쏘마에서 직접 도와드릴 수 있습니다. 이를 통해 고객님께서 안심하고 결제를 진행하실 수 있도록 지원해드립니다.
        </Typography>
      </Box>
      <ContactInfo>
        <Typography variant="h6" gutterBottom>
          문의 방법
        </Typography>
        <Typography variant="body1" paragraph>
          결제 금액이 2,000,000원을 초과하는 경우, 아래의 연락처로 문의해주시면 친절히 안내해드리겠습니다.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>이메일:</strong> gallery.soma@gmail.com<br />
          <strong>전화:</strong> 010-3313-8375 <br/>
          <strong>카카오톡:</strong> nubddaki
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <Typography variant="body1" sx={{ mr: 1 }}>
            <strong>Follow us:</strong>
          </Typography>
          <IconButton href="https://www.instagram.com/gallery_soma.official/" target="_blank" aria-label="Instagram">
            <Instagram />
          </IconButton>
        </Box>
      </ContactInfo>
      <Typography variant="body1" paragraph>
        고객님의 소중한 결제를 안전하게 처리하기 위해 최선을 다하겠습니다. 감사합니다.
      </Typography>
      <Typography variant="body1">
        갤러리 쏘마 드림
      </Typography>
    </Container>
  );
};

export default PaymentGuide;
