import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #b0b0b0 30%, #d4d4d4 90%)', // Subdued gray gradient
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(176, 176, 176, .3)',
  color: 'black',
  height: 48,
  padding: '0 30px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#d4d4d4',
    transform: 'scale(1.02)',
  },
}));

export default StyledButton;
