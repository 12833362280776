import React from 'react';
import useWindowDimensions from '../../utils/useWindowDimension';
import styles from './HomePageImages.module.css';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/common/LanguageSwitcher';

type imageObj = {
  name: string;
  url: string;
};

const homeImages: imageObj[] = [
  {
    name: 'Liberty & Freedom - black',
    url: 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/Artworks/Lee+Han/%E1%84%8C%E1%85%A1%E1%84%8B%E1%85%B2+(%E8%87%AA%E7%94%B1)+%5BLiberty%26Freedom%5D+%E2%80%93+1+(%E1%84%92%E1%85%B3%E1%86%A8).jpg',
  },
  {
    name: 'Liberty & Freedom - white',
    url: 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/Artworks/Lee+Han/%E1%84%8C%E1%85%A1%E1%84%8B%E1%85%B2+(%E8%87%AA%E7%94%B1)+%5BLiberty%26Freedom%5D+%E2%80%93+1+(%E1%84%87%E1%85%A2%E1%86%A8).jpg',
  },
  {
    name: 'Island - no.50',
    url: 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/Artworks/Lim+Chae+Gwang/3%E1%84%87%E1%85%A5%E1%86%AB-%E1%84%89%E1%85%A5%E1%86%B7_50%E1%84%87%E1%85%A7%E1%86%AB%E1%84%92%E1%85%A7%E1%86%BC.jpg',
  },
  {
    name: 'Looking Back',
    url: 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/Artworks/Wang+Moon+Gyeong/looking-back.jpg',
  },
  {
    name: 'fragment of memory',
    url: 'https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/Artworks/Wang+Moon+Gyeong/fragment-of-memory.jpg',
  },
];

const HomePageImages = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          width <= 1000 ? styles.wrapperWhenSmall : ''
        }`}
      >
        <img
          src={homeImages[2].url}
          className={`${width <= 1000 ? styles.box : styles.bigBox}`}
          alt={homeImages[2].name}
        />
        <img
          src={homeImages[0].url}
          className={`${width <= 1000 ? styles.box : styles.bigBox} `}
          alt={homeImages[0].name}
        />
        <img
          src={homeImages[4].url}
          className={`${width <= 1000 ? styles.box : styles.bigBox} `}
          alt={homeImages[4].name}
        />
        <img
          src={homeImages[3].url}
          className={`${width <= 1000 ? styles.box : styles.bigBox} `}
          alt={homeImages[3].name}
        />
        <img
          src={homeImages[1].url}
          className={`${width <= 1000 ? styles.box : styles.bigBox} `}
          alt={homeImages[1].name}
        />
      </div>
    </>
  );
};

export default HomePageImages;
