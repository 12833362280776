import React, { useEffect, useState } from 'react';
import {
  Drawer,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Input,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../../utils/useWindowDimension';
import styles from './SideMenu.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/redux';
import { useTranslation } from 'react-i18next';

type MenuItem = { name: SideMenuKey; to: string };
type NavigationItem = {
  name: string;
  content: React.ReactNode;
  to: string;
  handler: () => void;
  isHidden: boolean;
};

const sideMenuItems = {
  Exhibitions: 'exhibition',
  'Art Fairs': 'artfair',
  Shop: 'shop',
  'Visit SOMA': 'location',
  'SOMA Artists': 'artists',
} as const;

type SideMenuKey = keyof typeof sideMenuItems;

const menuItems: MenuItem[] = [
  { name: 'Exhibitions', to: '/exhibition' },
  { name: 'Art Fairs', to: '/artFair' },
  { name: 'Shop', to: '/merchandises' },
  { name: 'Visit SOMA', to: '/visit' },
  { name: 'SOMA Artists', to: '/artists' },
];

type SideMenuProps = {
  closeCart: () => void;
  openCart: () => void;
  isOpen: boolean;
  closeMenu: () => void;
};

const SideMenu = ({
  openCart,
  closeCart,
  isOpen,
  closeMenu,
}: SideMenuProps) => {
  const [isHidden, setIsHidden] = useState(false);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();

  useEffect(() => {
    setIsHidden(!isAuthenticated);
  }, [isAuthenticated]);

  const shoppingCartClickHandler = () => {
    closeMenu();
    openCart();
  };

  const accCircleClickHandler = () => {
    closeCart();
    closeMenu();
  };

  const navigation: NavigationItem[] = [
    {
      name: 'cart',
      content: <ShoppingCartIcon />,
      to: '#',
      handler: shoppingCartClickHandler,
      isHidden: isHidden,
    },
    {
      name: 'myPage',
      content: <AccountCircleIcon />,
      to: isAuthenticated ? '/myPage' : '/signup',
      handler: accCircleClickHandler,
      isHidden: false,
    },
  ];

  const { width } = useWindowDimensions();
  const getList = () => (
    <div
      style={{ width: width < 400 ? width / 3 : width / 4 }}
      onClick={closeMenu}
    >
      {menuItems.map((item) => (
        <Link className={styles.menu} to={item.to} key={item.name}>
          <ListItem>
            <ListItemText
              primary={t(`w.side-menu.direct.${sideMenuItems[item.name]}`)}
            />
          </ListItem>
        </Link>
      ))}
    </div>
  );

  return (
    <Drawer open={isOpen} anchor={'left'} onClose={closeMenu}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 3 }}>
          {navigation.map((item, i) => (
            <Link
              to={item.to}
              key={item.name}
              className={item.isHidden ? styles.hidden : ''}
            >
              <IconButton key={i} onClick={item.handler}>
                {item.content}
              </IconButton>
            </Link>
          ))}
        </Typography>
      </Box>
      {getList()}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mt: '1rem',
          gap: '1rem',
          ml: '0.5rem',
        }}
      >
        <IconButton
          component="a"
          href="https://www.instagram.com/gallery_soma.official/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'inherit' }}
        >
          <InstagramIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.linkedin.com/company/gallery-soma"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: 'inherit' }}
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          component="a"
          href="mailto:nubddak1@nate.com"
          sx={{ color: 'inherit' }}
        >
          <EmailIcon />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default SideMenu;
