import useWindowDimensions from '../../utils/useWindowDimension';

type ProductImageProps = {
  imgUrl: string;
};
const ProductImage = ({ imgUrl }: ProductImageProps) => {
  const { width, height } = useWindowDimensions();

  return (
    <div>
      <img src={imgUrl} />
    </div>
  );
};

export default ProductImage;
