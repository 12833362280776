import React from 'react';
import { Box, IconButton, Modal, Typography, Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const BlackLink = styled(Link)(({ theme }) => ({
  color: 'black',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

interface ContactSomaModalProps {
  open: boolean;
  handleClose: () => void;
}

const ContactSomaModal: React.FC<ContactSomaModalProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description"
    >
      <Box sx={modalStyle}>
        <StyledTypography id="contact-modal-title" variant="h6">
          Contact gallery SOMA
        </StyledTypography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <IconButton
            href="https://www.instagram.com/gallery_soma.official/"
            target="_blank"
            aria-label="Instagram"
          >
            <InstagramIcon />
          </IconButton>
          <Typography variant="body1" sx={{ ml: 1 }}>
            <BlackLink
              href="https://www.instagram.com/gallery_soma.official/"
              target="_blank"
            >
              Instagram
            </BlackLink>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <IconButton href="mailto:nubddak1@nate.com" aria-label="Email">
            <EmailIcon />
          </IconButton>
          <Typography variant="body1" sx={{ ml: 1 }}>
            <BlackLink href="mailto:nubddak1@nate.com">Email</BlackLink>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactSomaModal;
