import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { ApiServer } from '../../utils/axios';
import { useSelector } from 'react-redux';
import styles from './CartPage.module.css';
import { RootState } from '../../store/redux';
import CartItem from '../../components/cart/CartItem';
import BasicResponse from '../../models/common/BasicResponse';
import ArtworkItemCart from '../../models/artwork/ArtworkItemCart';
import { ArtworkOrder } from '../../models/order';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AnimatedWrapper from 'components/common/AnimatedWrapper';

const CartPage = () => {
  const [onDeliveryMethod, setOnDeliveryMethod] = useState(true);
  const { count } = useSelector((state: RootState) => state.cart);
  const [cartArtworks, setCartArtWorks] = useState<
      (ArtworkItemCart & { checked: boolean })[]
  >([]);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    ApiServer.get<BasicResponse<ArtworkItemCart[]>>('/artwork/shopping-cart/all')
        .then(({ data: { data } }) => {
          const cleanedData = data.map((item) => ({
            ...item,
            title: item.artworkItem.title || 'Unknown Title',
            artwork: {
              ...item.artworkItem.artwork,
              artist: {
                ...item.artworkItem.artwork.artist,
                name: item.artworkItem.artwork.artist.name || 'Unknown Artist',
              },
            },
            material: item.artworkItem.material || 'Unknown Material',
            medias:
                item.artworkItem.medias.length > 0
                    ? item.artworkItem.medias
                    : [{ path: 'path/to/default/image.jpg' }],
            width: item.artworkItem.width || '?',
            height: item.artworkItem.height || '?',
            depth: item.artworkItem.depth || '?',
            price: item.artworkItem.price || {
              price: 0,
              currency: { symbol: '₩' },
            },
            checked: false, // Add checked property for selection
          }));
          setCartArtWorks(cleanedData);
          setIsCartEmpty(data.length === 0);
        })
        .catch(() => {
          console.log('Error occurred while fetching cart items.');
          setError('Failed to load cart items. Please try again later.');
        })
        .finally(() => setLoading(false));
  }, []);

  const selectedOptions = useMemo(() => {
    return cartArtworks.filter((option) => option.checked) ?? [];
  }, [cartArtworks]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = event.target.checked;
    setSelectAll(newState);
    setCartArtWorks(
        cartArtworks.map((option) => ({ ...option, checked: newState }))
    );
  };

  const handleOptionChange =
      (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOptions = cartArtworks.map((option) =>
            option.id === id ? { ...option, checked: event.target.checked } : option
        );
        setCartArtWorks(updatedOptions);
        setSelectAll(updatedOptions.every((option) => option.checked));
      };

  const order = useCallback(() => {
    if (selectedOptions.length === 0) return;

    ApiServer.post<BasicResponse<ArtworkOrder>>('/order', {
      items: selectedOptions.map((option) => option.artworkItem.id),
    }).then(({ data: { data } }) => {
      console.log('purchase data', data);
      navigate(`/order/${data.orderId}`);
    });
  }, [selectedOptions, navigate]);

  const deliveryMethodHandler = (method: string) => {
    setOnDeliveryMethod(method === 'delivery');
  };

  const filteredOptions = useMemo(() => {
    return cartArtworks.filter(
        (option) =>
            (onDeliveryMethod && option.shippingMethodId === 1) ||
            (!onDeliveryMethod && option.shippingMethodId !== 1)
    );
  }, [cartArtworks, onDeliveryMethod]);

  useEffect(() => {
    const total = selectedOptions.reduce((sum, option) => {
      return sum + option.artworkItem.price.price;
    }, 0);
    setTotalPrice(total);
  }, [selectedOptions]);

  const totalItems = useMemo(() => {
    return cartArtworks.length;
  }, [cartArtworks]);

  if (loading) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
    );
  }

  if (error) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
    );
  }

  return (
      <AnimatedWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '90%' }}>
            <Box
                sx={{
                  mt: '10rem',
                  border: 1,
                  borderColor: 'lightgrey',
                  padding: '1rem',
                }}
            >
              <Box>
                <Typography variant="h4">Order/Payment</Typography>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box sx={{ width: '90%' }}>
                    <Box
                        sx={{ pt: '3rem', display: 'flex', flexDirection: 'row' }}
                    >
                      <Box
                          sx={{ mr: '1rem' }}
                          className={styles.shippingMethod}
                          onClick={() => deliveryMethodHandler('delivery')}
                          id={'1'}
                      >
                        <Typography
                            variant="h6"
                            sx={{ color: onDeliveryMethod ? 'black' : 'grey' }}
                        >
                          {t(`w.cart.select.by-delivery`)}
                        </Typography>
                      </Box>
                      <Divider
                          color={'secondary'}
                          orientation="vertical"
                          flexItem
                      ></Divider>
                      <Box
                          sx={{ ml: '1rem' }}
                          className={styles.shippingMethod}
                          onClick={() => deliveryMethodHandler('pickup')}
                          id={'2'}
                      >
                        <Typography
                            variant="h6"
                            sx={{ color: onDeliveryMethod ? 'grey' : 'black' }}
                        >
                          {t(`w.cart.select.in-store-pickup`)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {isCartEmpty ? (
                      <Typography
                          sx={{
                            marginTop: '5rem',
                            marginBottom: '5rem',
                          }}
                      >
                        {t(`w.cart.info.no-item`)}
                      </Typography>
                  ) : (
                      <Box
                          sx={{
                            pt: '2rem',
                            width: '90%',
                          }}
                      >
                        <Box
                            sx={{
                              border: 1,
                              borderColor: 'lightgrey',
                              padding: '1rem',
                            }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                                item
                                md={1}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                            >
                              {t(`w.common.info.total-price`)}:{' '}
                              {totalItems}
                            </Grid>
                            <Grid
                                item
                                md={1}
                                sx={{ display: 'flex', justifyContent: 'center' }}
                            >
                              <FormControlLabel
                                  label={t(`w.common.func.all`)}
                                  control={
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                  }
                              />
                            </Grid>
                            <Grid
                                item
                                md={4}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                            >
                              {t(`w.common.info.artwork-information`)}
                            </Grid>
                            <Grid
                                item
                                md={2}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                            >
                              {t(`w.common.info.quantity`)}
                            </Grid>
                            <Grid
                                item
                                md={2}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                            >
                              {t(`w.common.info.price`)}
                            </Grid>
                            <Grid
                                item
                                md={2}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                            >
                              {t(`w.common.info.delivery-cost`)}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ border: 1, borderColor: 'lightgrey' }}>
                          {filteredOptions.map((option) => (
                              <CartItem
                                  key={option.id}
                                  option={option}
                                  isCartEmpty={isCartEmpty}
                                  handleOptionChange={handleOptionChange}
                              />
                          ))}
                        </Box>
                        <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mt: '2rem',
                              mr: '2rem',
                            }}
                        >
                          <Typography variant="h6">
                            {t(`w.common.info.total-price`)}: ₩ {totalPrice}
                          </Typography>
                        </Box>
                      </Box>
                  )}
                </Box>
              </Box>
              {!isCartEmpty && (
                  <Box
                      sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}
                  >
                    <Button
                        variant="contained"
                        sx={{ borderRadius: '0', minWidth: '20rem', height: '3rem' }}
                        onClick={order}
                    >
                      <Typography variant={'button'}>
                        {t(`w.cart.direct.order`)}
                      </Typography>
                    </Button>
                  </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AnimatedWrapper>
  );
};

export default CartPage;
