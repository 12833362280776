import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { filterActions } from '../../store/filter-redux';
import {
  Box,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
    TextField,
    IconButton,
    InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterCategory, FilterType } from '../../store/filter-redux';
import { useTranslation } from 'react-i18next';

type CategorySideToImagesProps = {
  categoryArr: FilterType[];
  artistArr: FilterType[];
};

const CategorySideToImages = ({
  categoryArr,
  artistArr,
}: CategorySideToImagesProps) => {
  const [price, setPrice] = useState<number>(0);
  const [category, setCategory] = useState<number>(0);
  const [artist, setArtist] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const categoriesMonetary: FilterType[] = useMemo(
    () => [
      {
        name: 'category1',
        id: 1,
        content: 'under-500',
        category: FilterCategory.PRICE,
      },
      {
        name: 'category2',
        id: 2,
        content: 'btwn-500-and-1000',
        category: FilterCategory.PRICE,
      },
      {
        name: 'category3',
        id: 3,
        content: 'btwn-1000-and-2000',
        category: FilterCategory.PRICE,
      },
      {
        name: 'category4',
        id: 4,
        content: 'over-2000',
        category: FilterCategory.PRICE,
      },
    ],
    []
  );

  const handlePriceChange = useCallback(
    (event: SelectChangeEvent) => {
      setPrice(Number(event.target.value));
      const priceId = Number(event.target.value);
      const price = categoriesMonetary.find((price) => price.id === priceId);
      if (price) {
        // filterCtx.addFilter(price);
        dispatch(filterActions.addFilter({ filter: price }));
      }
    },
    [categoriesMonetary, dispatch]
  );

  const handleArtistChange = useCallback(
    (event: SelectChangeEvent) => {
      setArtist(Number(event.target.value));
      const artistId = Number(event.target.value);
      const artist = artistArr.find((artist) => artist.id === artistId);
      if (artist) {
        // filterCtx.addFilter(artist);
        dispatch(filterActions.addFilter({ filter: artist }));
      }
    },
    [artistArr, dispatch]
  );

  const handleCategoryChange = useCallback(
    (event: SelectChangeEvent) => {
      const categoryId = Number(event.target.value);
      const category = categoryArr.find(
        (category) => category.id === categoryId
      );
      if (category) {
        dispatch(filterActions.addFilter({ filter: category }));
      }
      setCategory(Number(event.target.value));
    },
    [categoryArr, dispatch]
  );


    const handleSearch =useCallback( () => {
        // 검색 핸들러 로직을 여기에 작성합니다.
        console.log('Search term:', searchTerm);
        const search:FilterType = {
            name: searchTerm,
            id: searchTerm,
            content: searchTerm,
            category: FilterCategory.KEYWORD
        };
        dispatch(filterActions.addFilter({filter: search}));
    },[dispatch, searchTerm]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };




    const categorySet = {
    type: 'Category',
    content: (
      <Select
        labelId={`select-category`}
        id={`select-category-small`}
        value={`${category}`}
        label={`category`}
        onChange={handleCategoryChange}
        sx={{ height: 'auto', width: { xs: '6rem', sm: 'auto' } }}
      >
        {categoryArr.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {t(`w.filter.direct.${category.content}`)}
          </MenuItem>
        ))}
      </Select>
    ),
  };

  const artistSet = {
    type: 'Artist',
    content: (
      <Select
        labelId={`select-artist`}
        id={`select-artist-small`}
        value={`${artist}`}
        label={`artist`}
        onChange={handleArtistChange}
        sx={{ height: 'auto', width: { xs: '6rem', sm: 'auto' } }}
      >
        {artistArr.map((artist) => (
          <MenuItem key={artist.id} value={artist.id}>
            {t(`w.filter.direct.${artist.content}`)}
          </MenuItem>
        ))}
      </Select>
    ),
  };

  const priceSet = {
    type: 'Price',
    content: (
      <Select
        labelId={`select-price`}
        id={`select-price-small`}
        value={`${price}`}
        label={`price`}
        onChange={handlePriceChange}
        sx={{ height: 'auto', width: { xs: '6rem', sm: 'auto' } }}
      >
        {categoriesMonetary.map((price) => (
          <MenuItem key={price.id} value={price.id}>
            {t(`w.filter.direct.${price.content}`)}
          </MenuItem>
        ))}
      </Select>
    ),
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, marginTop:"2rem" }}>

        <div style={{display: 'flex', alignItems: 'center'}}>
            <TextField
                id="artwork-search"
                label="Search field"
                type="search"
                variant="standard"
                value={searchTerm}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>


        <div>
            <Typography variant="h5" sx={{m: '0.5rem'}}>
                {categorySet.type}
            </Typography>
            <FormControl sx={{m: 1, minWidth: 105}} size="small">
                <InputLabel id="small-select-label">{categorySet.type}</InputLabel>
                {categorySet.content}
            </FormControl>
        </div>

        <div>
            <Typography variant="h5" sx={{m: '0.5rem'}}>
                {artistSet.type}
            </Typography>
        <FormControl sx={{ m: 1, minWidth: 105 }} size="small">
          <InputLabel id="small-select-label">{artistSet.type}</InputLabel>
          {artistSet.content}
        </FormControl>
      </div>
      <div>
        <Typography variant="h5" sx={{ m: '0.5rem' }}>
          {priceSet.type}
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 105 }} size="small">
          <InputLabel id="small-select-label">{priceSet.type}</InputLabel>
          {priceSet.content}
        </FormControl>
      </div>
    </Box>
  );
};

export default CategorySideToImages;
