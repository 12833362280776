import React from 'react';
import { Box } from '@mui/material';
import IndividualArtWorkCard from '../../components/artworks/IndividualArtWorkCard';

const ArtworkIndividualPage = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: { xs: '90%', md: '70%', lg: '80%' } }}>
        <IndividualArtWorkCard />
      </Box>
    </Box>
  );
};

export default ArtworkIndividualPage;
