import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import {RootState} from 'store/redux';
import usePayment from 'hooks/usePayment';
import {ArtworkOrder} from 'models/order';
import {BasicResponse} from 'models/common';
import {ApiServer} from 'utils/axios';
import styles from './OrderConfirmPage.module.css'; // 스타일을 위한 CSS 모듈
import useCDNResource from 'utils/useCDNResource';
import PaymentCheck from 'components/order/PaymentCheck';
import AnimatedWrapper from 'components/common/AnimatedWrapper';
import {useTranslation} from 'react-i18next';
import {User, UserAddress} from 'models/user';
import DaumAddressSearch from 'components/common/DaumAddressSearch';
import ToggleSwitch from 'components/common/ToggleSwitch';
import GlobalAddressFinder from 'components/common/GlobalAddressFinder';

function OrderConfirmPage() {
    const {orderId} = useParams();
    const [order, setOrder] = useState<ArtworkOrder | undefined>(undefined);
    const [address, setAddress] = useState<UserAddress | string>('');
    const {requestPayment} = usePayment();
    const {user} = useSelector((state: RootState) => state.user);
    const {getCDNResource} = useCDNResource();
    const [isKoreanAddress, setIsKoreanAddress] = useState<boolean>(false);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [defaultAddress, setDefaultAddress] = useState<UserAddress | undefined>(
        undefined
    );
    const priceLimit = process.env.REACT_APP_NICEPAY_LIMIT; // limit for the nice pay one time charge
    const {t} = useTranslation();
    const navigate = useNavigate();

    // default address 가져오기
    useEffect(() => {
        ApiServer.get<BasicResponse<User>>(`/user/me`)
            .then(({data: {data}}) => {
                setDefaultAddress(data?.addressPrimary);
                setAddress(data?.addressPrimary?.address);
                console.log(data?.addressPrimary);
            })
            .catch((e) => console.log(e));
    }, []);

    useEffect(() => {
        ApiServer.get<BasicResponse<ArtworkOrder>>(`/order`, {
            params: {orderId},
        })
            .then(({data: {data}}) => {
                console.log('DATA: ', data);
                setOrder(data);

                if (user?.addresses && user.addresses.length > 0) {
                    const defaultAddress = `${user.addresses[0].street}, ${user.addresses[0].city}, ${user.addresses[0].state}, ${user.addresses[0].zip}`;
                    setAddress(defaultAddress);
                }

                const price = data.items.reduce(
                    (acc, item) => acc + item.artworkItem.price.price,
                    0
                );
                setTotalPrice(price);
            })
            .catch((e) => {
                console.log(e);
            });
    }, [orderId, user]);

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleToggle = (newState: boolean) => {
        setIsKoreanAddress(newState);
    };

    const handleAddressSelected = (selectedAddress: string) => {
        setAddress(selectedAddress);
    };

    const payment = useCallback(() => {
        const [selectedAddress] = user?.addresses ?? [];
        if (order && user && selectedAddress) {
            requestPayment(
                order,
                user,
                selectedAddress,
                ({error_code, error_msg, imp_uid, merchant_uid}) => {
                    if (error_code) {
                        // TODO: handle failure
                        console.log(error_code, error_msg);
                    } else {
                        ApiServer.post('/payment', {
                            impUid: imp_uid,
                            orderId: merchant_uid,
                            price: totalPrice,
                        }).then(({data}) => {
                            console.log('payment success', data);
                            navigate("/order/result");
                        });
                    }
                }
            );
        }
    }, [user, order, requestPayment, totalPrice]);

    return (
        <AnimatedWrapper>
            <Box className={styles.container} sx={{marginTop: '10rem'}}>
                <Typography variant="h4" className={styles.header}>
                    {t(`t.cart.confirmation`)}
                </Typography>
                {order ? (
                    <>
                        <Box className={styles.addressSection}>
                            <Typography variant="h6" className={styles.subHeader}>
                                {t(`w.cart.info.delivery-address`)}
                            </Typography>
                            {
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={
                                        address !== `undefined, undefined, undefined, undefined`
                                            ? address
                                            : defaultAddress?.address
                                    }
                                    onChange={handleAddressChange}
                                    className={styles.addressInput}
                                    sx={{mb: '2rem'}}
                                />
                            }
                            <ToggleSwitch isOn={isKoreanAddress} onToggle={handleToggle}/>
                            <Typography sx={{mt: '0.2rem'}}>
                                {isKoreanAddress
                                    ? t(`s.cart.info.ko-addy`)
                                    : t(`s.cart.info.not-ko-addy`)}
                            </Typography>
                            {isKoreanAddress ? (
                                <DaumAddressSearch onAddressSelected={handleAddressSelected}/>
                            ) : (
                                <GlobalAddressFinder
                                    onAddressSelected={handleAddressSelected}
                                />
                            )}
                        </Box>
                        <Box className={styles.orderSection}>
                            <Typography variant="h6" className={styles.subHeader}>
                                {t(`w.cart.info.order-details`)}
                            </Typography>
                            <Grid container spacing={2} className={styles.orderGrid}>
                                {order.items.map((item) => (
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        key={item.id}
                                        className={styles.orderItem}
                                    >
                                        <Box className={styles.itemContainer}>
                                            <img
                                                src={getCDNResource(
                                                    item.artworkItem.medias[0]?.media.path
                                                )}
                                                alt={item.artworkItem.title}
                                                className={styles.itemImage}
                                            />
                                            <Box className={styles.itemDetails}>
                                                <Typography variant="body1">
                                                    {item.artworkItem.title}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {item.artworkItem.artist?.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2">{`₩ ${item.artworkItem?.price.price}`}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        <Box>
                            <Typography variant="h5">
                                {t(`w.common.info.total-price`)}: ₩{totalPrice}
                            </Typography>
                        </Box>
                        <Box className={styles.buttonContainer} sx={{mt: '1rem'}}>
                            {totalPrice <= Number(priceLimit) ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={payment}
                                    className={styles.orderButton}
                                >
                                    {t(`w.cart.direct.confirm-and-pay`)}
                                </Button>
                            ) : (
                                <PaymentCheck amount={totalPrice}/>
                            )}
                        </Box>
                    </>
                ) : (
                    <Typography>{t(`w.common.info.loading`)}</Typography>
                )}
            </Box>
        </AnimatedWrapper>
    );
}

export default OrderConfirmPage;
