import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', mt: '4rem', mb: '2rem' }}
    >
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem', // Added gap for spacing between text
        }}
      >
        <Box>
          <Typography
            sx={{
              typography: { xs: 'caption', sm: 'body2' }, // Smaller text for xs
            }}
          >
            Business name: Gallery SOMA | CEO: Jinkyung Hu | Personal Info
            Protector: Jinkyung Hu | email: gallery.soma@gmail.com
          </Typography>
        </Box>
        <Typography
          sx={{
            typography: { xs: 'caption', sm: 'body2' }, // Smaller text for xs
          }}
        >
          Address: 222, Samsong-ro, Deogyang-gu, Goyang-si, Gyeonggi-do,
          Republic of Korea | Company Registration Number: 110-18-57237 |
          Mail-Order-Sales Registration Number: 1698-2142-8030-6877 <br />
        </Typography>
        <Typography
          sx={{
            typography: { xs: 'caption', sm: 'body2' }, // Smaller text for xs
          }}
        >
          Terms of Use | Privacy Policy | Check Business Info
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
          <Box
            component="img"
            sx={{
              height: { lg: 70, md: 60, sm: 50, xs: 35 },
              width: { lg: 335.3, md: 287.4, sm: 239.5, xs: 167.65 },
              maxHeight: { xs: 400, md: 167 },
              maxWidth: { xs: 350, md: 340 },
            }}
            src="https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/horizontal_logo.webp"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '1rem' }}>
          <IconButton
            component="a"
            href="https://www.instagram.com/gallery_soma.official/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'inherit' }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.linkedin.com/company/gallery-soma"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: 'inherit' }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component="a"
            href="mailto:nubddak1@nate.com"
            sx={{ color: 'inherit' }}
          >
            <EmailIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
