import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  CardMedia,
  Card,
  CardActionArea,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/redux';
import { authActions } from '../store/auth-redux';
import { ApiServer } from 'utils/axios';
import { BasicResponse } from 'models/common';
import FavoriteItem from 'models/favorite/FavoriteItem';
import useCDNResource from 'utils/useCDNResource';
import useWindowDimensions from 'utils/useWindowDimension';
import ArtWorkIsInCart from 'models/artwork/ArtWorkIsInCart';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import AnimatedWrapper from 'components/common/AnimatedWrapper';
import { useTranslation } from 'react-i18next';

const MyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { user } = useSelector((state: RootState) => state.user);
  const [favoriteArtworks, setFavoriteArtworks] = useState<FavoriteItem[]>(); // 좋아하는 작품 데이터 상태
  const [cartArtworks, setCartArtworks] = useState<ArtWorkIsInCart[]>(); // 카트에 있는 데이터 상태
  const [error, setError] = useState<string | null>(null);
  const { getCDNResource } = useCDNResource();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  useEffect(() => {
    ApiServer.get<BasicResponse<FavoriteItem[]>>(`/artwork/favorite/all`)
      .then(({ data: { data } }) => {
        setFavoriteArtworks(data);
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || 'Failed to load favorite artworks';
        setError(errorMessage); // 에러 메시지 설정
        console.error('Error fetching favorite artworks:', errorMessage);
      });

    ApiServer.get<BasicResponse<ArtWorkIsInCart[]>>(
      `/artwork/shopping-cart/all`
    )
      .then(({ data: { data } }) => {
        console.log('shopping cart items: ', data);
        setCartArtworks(data);
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || 'Failed to load cart artworks';
        setError(errorMessage); // 에러 메시지 설정
        console.error('Error fetching cart artworks:', errorMessage);
      });
  }, []);

  const handleArtworkClick = (artworkId: number) => {
    navigate(`/merchandises/${artworkId}`);
  };

  const handleViewAllFavorites = () => {
    navigate('/myPage/favorites'); // /myPage/favorites 라는 경로에 전체 좋아하는 작품들을 표시하는 페이지가 존재
  };

  // 스타일링
  const AvatarWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    '&:hover .avatar-overlay': {
      opacity: 1,
      cursor: 'pointer', // 커서가 포인터로 변경
    },
  }));

  const AvatarOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    borderRadius: '50%', // 동그란 모양을 위해 추가
    opacity: 0,
    transition: 'opacity 0.3s',
  }));

  const NoItemsMessage = styled(Typography)(({ theme }) => ({
    margin: '2rem auto', // 상하좌우로 margin 추가
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <AnimatedWrapper>
      <Box sx={{ ml: '2rem', mr: '2rem' }}>
        <Box sx={{ display: 'flex' }}>
          <Link to="/myPage/edit" style={{ textDecoration: 'none' }}>
            <AvatarWrapper>
              <Avatar alt="avatar" src={''} sx={{ width: 200, height: 200 }} />
              <AvatarOverlay className="avatar-overlay">
                <Typography variant="h6">Edit Profile</Typography>
              </AvatarOverlay>
            </AvatarWrapper>
          </Link>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              ml: '2rem',
            }}
          >
            <Typography variant="h5">{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Box>{`${user?.birthday}`}</Box>
            <Box>{`${user?.email}`}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '4rem',
            marginBottom: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t(`w.my.favorites`)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: width < 1300 ? 'flex' : '',
            justifyContent: width < 1300 ? 'center' : '',
            alignItems: 'center',
          }}
        >
          {favoriteArtworks?.length ? (
            <Grid container spacing={2}>
              {favoriteArtworks
                ?.filter((favArtwork) => favArtwork.status === 'ADDED')
                .slice(0, Math.min(favoriteArtworks.length, 4)) // 최대 4개만 보여주기
                .map((favArtwork) => (
                  <Grid item xs={12} sm={6} md={3} key={favArtwork.id}>
                    <Card sx={{ maxWidth: 345, borderRadius: 0 }}>
                      <CardActionArea
                        onClick={() =>
                          handleArtworkClick(favArtwork.artworkItem.id)
                        }
                      >
                        <CardMedia
                          component="img"
                          height="450"
                          image={
                            getCDNResource(
                              favArtwork.artworkItem.medias[0]?.media.path
                            ) || 'defaultImage.jpg'
                          }
                          alt={favArtwork.artworkItem.title}
                        />
                        <Box sx={{ margin: '1rem' }}>
                          <Typography gutterBottom variant="h5" component="div">
                            {favArtwork.artworkItem.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {favArtwork.artworkItem.artist?.name}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                minHeight: '20rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NoItemsMessage>{t(`s.merchandise.my.info.no-favorite`)}</NoItemsMessage>
            </Box>
          )}
        </Box>
        {favoriteArtworks && favoriteArtworks.length > 0 && (
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button variant="outlined" onClick={handleViewAllFavorites}>
              View All
            </Button>
          </Box>
        )}
        <Box
          sx={{ marginY: 2, display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="h6" gutterBottom>
            {t(`w.my.cart`)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: width < 1300 ? 'flex' : '',
            justifyContent: width < 1300 ? 'center' : '',
            alignItems: 'center',
          }}
        >
          {cartArtworks?.length ? (
            <Grid container spacing={2}>
              {cartArtworks
                ?.slice(0, Math.min(4, cartArtworks.length)) // 최대 4개만 보여주기
                .map((cartArtwork) => (
                  <Grid item xs={12} sm={6} md={3} key={cartArtwork.id}>
                    <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea
                        onClick={() =>
                          handleArtworkClick(cartArtwork.artworkItem.id)
                        }
                      >
                        <CardMedia
                          component="img"
                          height="450"
                          image={
                            getCDNResource(
                              cartArtwork.artworkItem.medias[0]?.media.path
                            ) || 'defaultImage.jpg'
                          }
                          alt={cartArtwork.artworkItem.title}
                        />
                        <Box sx={{ margin: '1rem' }}>
                          <Typography gutterBottom variant="h5" component="div">
                            {cartArtwork.artworkItem.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {cartArtwork.artworkItem.artist?.name}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                minHeight: '20rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NoItemsMessage>{t(`s.merchandise.my.info.no-cart`)}</NoItemsMessage>
            </Box>
          )}
        </Box>
        {cartArtworks && cartArtworks.length > 0 && (
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link to={'/cart'}>
              <Button variant="outlined" onClick={handleViewAllFavorites}>
                View All
              </Button>
            </Link>
          </Box>
        )}
        <Box
          sx={{ marginY: '10rem', display: 'flex', justifyContent: 'center' }}
        >
          {isAuthenticated && (
            <Button
              onClick={() => {
                dispatch(authActions.logout());
                window.localStorage.clear();
                navigate('/');
              }}
              variant="contained"
              sx={{ borderRadius: '0' }}
            >
              LOG OUT
            </Button>
          )}
        </Box>
      </Box>
    </AnimatedWrapper>
  );
};

export default MyPage;
