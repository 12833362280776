import React, { useState } from 'react';
import { Box, Typography, IconButton, Modal, Paper, Link } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';
import StyledButton from 'components/common/StyledButton';
import PaymentGuide from 'components/common/PaymentGuide';
import ContactSomaModal from 'components/common/ContactSomaModal';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

interface PaymentCheckProps {
  amount: number;
}

const PaymentCheck: React.FC<PaymentCheckProps> = ({ amount }) => {
  const [open, setOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const threshold = 2000000;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => setContactOpen(false);

  return (
    <Box sx={{ textAlign: 'center', mt: 5 }}>
      {amount > threshold ? (
        <>
          <StyledTypography variant="body1" sx={{ mb: 2 }}>
            Payment amount exceeds 2,000,000 won. Please contact gallery SOMA
            for more information.
            <IconButton onClick={handleOpen} aria-label="info">
              <InfoIcon fontSize="small" />
            </IconButton>
          </StyledTypography>
          <StyledButton onClick={handleContactOpen}>
            Contact gallery SOMA
          </StyledButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <StyledTypography id="modal-title" variant="h6">
                Why Contact gallery SOMA?
              </StyledTypography>
              <StyledTypography id="modal-description" sx={{ mt: 2 }}>
                <PaymentGuide />
              </StyledTypography>
            </Box>
          </Modal>
          <ContactSomaModal
            open={contactOpen}
            handleClose={handleContactClose}
          />
        </>
      ) : (
        <StyledTypography variant="h6">결제 금액: {amount}원</StyledTypography>
      )}
    </Box>
  );
};

export default PaymentCheck;
