import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArtWorkItem from '../../models/artwork/ArtWorkItem';
import useCDNResource from '../../utils/useCDNResource';
import ArtworkOverlay from '../overlay/ArtworkOverlay';
import ArtworkSoldOverlay from 'components/overlay/ArtworkSoldOverlay';
import ArtworkHoldOverlay from 'components/overlay/ArtworkHoldOverlay';

interface ArtworkItemProps {
  artwork: ArtWorkItem;
}

const ArtworkItem: React.FC<ArtworkItemProps> = ({ artwork }) => {
  const { getCDNResource } = useCDNResource();
  const navigate = useNavigate();
  const thumbnail = useMemo(() => {
    const [thumbnailMedia] = artwork.medias?.slice(-1) ?? [];
    return getCDNResource(thumbnailMedia?.media?.path);
  }, [artwork, getCDNResource]);

  console.log('artwork item', artwork, thumbnail);

  if (!thumbnail || artwork?.status === 'Unregistered') return null;

  const renderStatusOverlay = () => {
    if (artwork?.status === 'SOLD') {
      return <ArtworkSoldOverlay />;
    } else if (artwork?.status === 'HOLD') {
      return <ArtworkHoldOverlay />;
    }
    return null;
  };

  return (
    <Box sx={{ position: 'relative', marginBottom: '40px' }}>
      <img
        src={thumbnail || 'defaultImage.jpg'}
        alt={artwork.title}
        loading="lazy"
        style={{ width: '100%', height: 'auto', display: 'block' }}
        onClick={() => navigate(`/merchandises/${artwork.id}`)}
      />
      {renderStatusOverlay()}
      <ArtworkOverlay
        artwork={artwork}
        onOverlayClick={() => navigate(`/merchandises/${artwork.id}`)}
      />
    </Box>
  );
};

export default ArtworkItem;
