import React from 'react';
import ArtistListWithImg from '../../components/artist/ArtistListWithImg';
import { Box } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import AnimatedWrapper from 'components/common/AnimatedWrapper';

const ArtistPage = () => {
  return (
    <AnimatedWrapper>
      <ArtistListWithImg />
    </AnimatedWrapper>
  );
};

export default ArtistPage;
