import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/user';

interface UserState {
  user: User | null | undefined; // Allow both null and undefined
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | undefined | null>) {
      state.user = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
