import React, { useState } from 'react';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { ApiServer } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handlePasswordReset = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setLoading(true);
        setError(null);

        const email = sessionStorage.getItem('email');
        if (!email) {
            setError('No email found. Try again from the beginning');
            setLoading(false);
            return;
        }

        ApiServer.post('/auth/reset/password', {
            email,
            password,
        })
            .then((response) => {
                if (response.data.status.code === 'OK') {
                    navigate('/');
                } else {
                    setError('Failed to reset password. Please try again.');
                }
            })
            .catch((err) => {
                console.error('Error resetting password:', err);
                setError('An error occurred. Please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '5rem' }}>
            <Typography variant="h4" gutterBottom>
                Reset Password
            </Typography>
            <Box sx={{ width: '100%', maxWidth: '400px', mt: '2rem' }}>
                <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {error && (
                    <Typography color="error" sx={{ mt: '1rem' }}>
                        {error}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: '2rem' }}
                    onClick={handlePasswordReset}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Reset Password'}
                </Button>
            </Box>
        </Box>
    );
};

export default ResetPassword;
