import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  MenuItem,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Slide,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useWindowDimensions from '../../utils/useWindowDimension';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/redux';
import styles from './Header.module.css';
import LanguageSwitcher from 'components/common/LanguageSwitcher';
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  openMenu: () => void;
  openCart: () => void;
};

function Header({ openMenu, openCart }: HeaderProps) {
  const { width } = useWindowDimensions();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [isHidden, setIsHidden] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsHidden(!isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (window.scrollY === 0 && event.deltaY < 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const springProps = useSpring({
    height: showButton
      ? width >= 1540
        ? '200px'
        : '180px'
      : width >= 1540
      ? '150px'
      : '130px',
    config: { tension: 170, friction: 26 },
  });

  const navigation = [
    {
      id: 1,
      name: 'exhibition',
      type: 'text',
      content: <Typography>{t(`w.main.navmenu.exhibition`)}</Typography>,
      to: '/exhibition',
    },
    {
      id: 2,
      name: 'art-fairs',
      type: 'text',
      content: <Typography>{t(`w.main.navmenu.artfair`)}</Typography>,
      to: '/artFair',
    },
    {
      id: 3,
      name: 'shop',
      type: 'text',
      content: <Typography>{t(`w.main.navmenu.shop`)}</Typography>,
      to: '/merchandises',
    },
    {
      id: 4,
      name: 'artists',
      type: 'text',
      content: <Typography>{t(`w.main.navmenu.artists`)}</Typography>,
      to: '/artists',
    },
  ];

  const iconNavigation = [
    {
      name: 'cart',
      type: 'icon',
      to: '#',
      content: <ShoppingCartIcon onClick={openCart} />,
      isHidden: isHidden,
    },
    {
      name: 'myPage',
      type: 'icon',
      to: isAuthenticated ? '/myPage' : '/signup',
      content: <AccountCircleIcon />,
      isHidden: false,
    },
  ];

  return (
    <animated.div style={springProps}>
      <AppBar
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          transition: 'height 0.3s ease-in-out',
        }}
      >
        <Slide direction="down" in={showButton} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              bgcolor: 'primary.main',
              paddingTop: '1rem',
            }}
          >
            <LanguageSwitcher />
          </Box>
        </Slide>
        <Toolbar>
          <Box>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={openMenu}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row', md: 'column' },
                alignItems: 'end',
              }}
            >
              <Link to="/">
                <Box
                  sx={{
                    height: { lg: 70, md: 60, sm: 50, xs: 35 },
                    width: { lg: 335.3, md: 287.4, sm: 239.5, xs: 167.65 },
                    maxHeight: { xs: 400, md: 167 },
                    maxWidth: { xs: 350, md: 340 },
                    mt: '1.2rem', // 로고 위에 마진 추가
                    mb: { xs: '1.2rem', sm: '0rem' },
                  }}
                  component="img"
                  src="https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/test1/horizontal_logo.webp"
                />
              </Link>
              <Typography
                sx={{
                  width: { lg: 335.3, md: 287.4, xs: 'auto' },
                  fontSize: '13px',
                  display: { xs: 'none', sm: 'inline-block' },
                  paddingLeft: { xs: '1rem', md: 'none' },
                  mb: '1.2rem',
                }}
              >
                {t('w.main.navmenu.vision')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: width >= 1540 ? 'flex' : 'none',
                width: '40%',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {navigation.map((item) => (
                <Link to={item.to} key={item.id} className={styles.menuItem}>
                  <Typography sx={{ p: '1rem' }}>{item.content}</Typography>
                </Link>
              ))}
              {!isAuthenticated && (
                <Link to="/login" className={styles.menuItem}>
                  <Typography sx={{ p: '1rem' }}>
                    {t(`w.main.navmenu.login`)}
                  </Typography>
                </Link>
              )}
              {iconNavigation.map((item) => (
                <Link
                  to={item.to}
                  className={item.isHidden ? styles.hidden : styles.menuItem}
                  key={item.name}
                >
                  <MenuItem
                    key={item.name}
                    sx={{ pl: '1rem' }}
                    className={styles.hidden}
                  >
                    {item.content}
                  </MenuItem>
                </Link>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </animated.div>
  );
}

export default Header;
