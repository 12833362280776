import axios, { AxiosError, HttpStatusCode } from 'axios';
import BasicResponse from '../models/common/BasicResponse';
import UserToken from '../models/user/UserToken';

export const ApiServer = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

const getAccessToken = () => {
  return (
    window.localStorage.getItem('access-token') ||
    window.sessionStorage.getItem('access-token')
  );
};

const getRefreshToken = () => {
  return (
    window.localStorage.getItem('refresh-token') ||
    window.sessionStorage.getItem('refresh-token')
  );
};

ApiServer.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken?.startsWith('Bearer')) {
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);

ApiServer.interceptors.response.use(
  (config) => {
    return config;
  },
  (error: AxiosError) => {
    if (
      error.response?.status === HttpStatusCode.Unauthorized &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error.response?.data?.message === 'ACCOUNT-03'
    ) {
      const refreshToken = getRefreshToken();
      if (refreshToken?.startsWith('Bearer')) {
        axios
          .post<BasicResponse<UserToken>>(
            `http://dev.api.gallerysoma.co.kr/auth/refresh/token`,
            null,
            {
              headers: {
                Authorization: refreshToken,
              },
            }
          )
          .then(({ data: { data } }) => {
            if (data && data.grantType && data.accessToken) {
              const newAccessToken = `${data.grantType} ${data.accessToken}`;
              const newRefreshToken = `${data.grantType} ${data.refreshToken}`;

              // Store the new tokens in the appropriate storage
              if (window.localStorage.getItem('access-token')) {
                window.localStorage.setItem('access-token', newAccessToken);
                window.localStorage.setItem('refresh-token', newRefreshToken);
              } else {
                window.sessionStorage.setItem('access-token', newAccessToken);
                window.sessionStorage.setItem('refresh-token', newRefreshToken);
              }

              if (error.config) {
                ApiServer.request({
                  ...error.config,
                  headers: {
                    ...error.config.headers,
                    Authorization: newAccessToken,
                  },
                });
              }
            }
          })
          .catch(() => {
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.href = '/login';
            return Promise.reject(error);
          });
      }
    }
    return Promise.reject(error);
  }
);
