import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/dm-mono/300-italic.css';
import '@fontsource/dm-mono/400-italic.css';
import '@fontsource/dm-mono/500-italic.css';
import {
  IMPRequestPayParams,
  IMPRequestPayResponseCallback,
} from 'models/iamport';
import './locales/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
export let persistor = persistStore(store); //새로 고침, 종료해도 지속될 store 생성

export type Iamport = {
  init: (accountId: string) => void;
  request_pay: (
    params: IMPRequestPayParams,
    callback?: IMPRequestPayResponseCallback
  ) => void;
};

declare global {
  interface Window {
    IMP?: Iamport;
  }
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
