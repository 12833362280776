import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useFavorite from "hooks/useFavorite";
import useCart from "hooks/useCart";
import ArtWorkItem from "models/artwork/ArtWorkItem";

interface ArtworkOverlayProps {
  artwork: ArtWorkItem;
  onOverlayClick: () => void;
}

const ArtworkOverlay: React.FC<ArtworkOverlayProps> = ({
  artwork,
  onOverlayClick,
}) => {
  const { isLiked, toggleLike, favLoading } = useFavorite(artwork.id);
  const { isInCart, toggleCart, cartLoading } = useCart(artwork.id);

  return (
    <Box
      onClick={onOverlayClick}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        opacity: 0,
        "&:hover": {
          opacity: 1,
        },
      }}
    >
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          toggleLike();
        }}
        color="error"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          zIndex: 2,
        }}
        disabled={favLoading}
      >
        {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          toggleCart();
        }}
        color="primary"
        sx={{
          position: "absolute",
          top: 48,
          right: 8,
          zIndex: 2,
        }}
        disabled={cartLoading}
      >
        {isInCart ? <ShoppingCartIcon /> : <AddShoppingCartIcon />}
      </IconButton>
      <Typography variant="body2">{artwork.title}</Typography>
      <Typography variant="body2">{artwork?.artist?.name}</Typography>
    </Box>
  );
};

export default ArtworkOverlay;
