// MyEditPage.jsx

import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/redux';
import { userActions } from 'store/user-redux';
import { ApiServer } from 'utils/axios';
import MyAddresses from 'components/address/MyAddresses';
import { User } from 'models/user';
import UserInformation from 'components/user/UserInformation';
import AnimatedWrapper from 'components/common/AnimatedWrapper';
import { useTranslation } from 'react-i18next';
import { ContactSupportOutlined } from '@mui/icons-material';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().min(
    8,
    'Password should be of minimum 8 characters length'
  ),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  birthday: Yup.date().required('Required'),
});

const addressValidationSchema = Yup.object({
  name: Yup.string().required('Required'),
  receiver: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  zipcode: Yup.string().required('Required'),
  contact: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
});

const MyEditPage = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [defaultAddressId, setDefaultAddressId] = useState<number | undefined>(
    0
  );
  const { t } = useTranslation();

  const deleteAddress = (addressId: number) => {
    setLoading(true);
    ApiServer.delete(`user/address/${addressId}`)
    .then(()=>{
      alert(`Sucessfully removed the address!`);
      setError('');
    })
    .catch((error) => {
      setError(`Failed to remove the address.`);
      console.log(`Error removing the address.`);
    })
    .finally(()=>{
      setLoading(false)
    })
  }

  //TODO: setting default address.
  const handleSelectDefaultAddress = (addressId: number) => {
    setLoading(true);
    ApiServer.put(`/user/address/primary/${addressId}`)
      .then(() => {
        alert('Default address set successfully!');
        setDefaultAddressId(addressId);
        setError('');
      })
      .catch((error) => {
        setError('Failed to set default address');
        console.error('Error setting default address:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email || '',
      password: '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      birthday: user?.birthday || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (!user) return;

      const updatedUser: User = {
        ...user,
        email: values.email,
        password: values.password || undefined, // Ensure password is optional
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: values.birthday,
        authorities: user.authorities ?? undefined,
        addresses: user.addresses ?? undefined,
      };

      setLoading(true);
      ApiServer.post(`/user/${user.id}`, updatedUser)
        .then(() => {
          alert('Profile updated successfully!');
          dispatch(userActions.setUser(updatedUser));
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || 'Failed to update profile';
          setError(errorMessage);
          console.error('Error updating profile:', errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const addressFormik = useFormik({
    initialValues: {
      name: '',
      receiver: '',
      address: '',
      zipcode: '',
      contact: '',
      country: '',
    },
    validationSchema: addressValidationSchema,
    onSubmit: (values) => {
      setAddressLoading(true);
      setAddressError(null);

      ApiServer.post(`user/address`, values)
        .then(() => {
          alert('Address updated successfully!');
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || 'Failed to update address';
          setAddressError(errorMessage);
          console.error('Error updating address:', errorMessage);
        })
        .finally(() => {
          setAddressLoading(false);
        });
    },
  });

  return (
    <AnimatedWrapper>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Edit Profile</Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{
              mt: 3,
              width: '100%',
              backgroundColor: 'white',
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <UserInformation />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label={t(`w.common.info.first-name`)}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label={t(`w.common.info.last-name`)}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={t(`w.common.info.email`)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="birthday"
                  name="birthday"
                  label={t(`w.common.info.birthday`)}
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.birthday}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.birthday && Boolean(formik.errors.birthday)
                  }
                  helperText={formik.touched.birthday && formik.errors.birthday}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label={t(`w.common.info.password`)}
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ paddingX: 5 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Update Profile'}
              </Button>
            </Box>
          </Box>
          <Typography component="h1" variant="h4" sx={{ mt: '5rem' }}>
            Edit Address
          </Typography>
          <Box
            component="form"
            onSubmit={addressFormik.handleSubmit}
            sx={{
              mt: 3,
              width: '100%',
              backgroundColor: 'white',
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <MyAddresses
              addresses={user?.addresses}
              onDelete={(id)=>deleteAddress(id)}
              onSelectDefault={handleSelectDefaultAddress}
              defaultAddressId={defaultAddressId}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label={t(`w.common.info.address-name`)}
                  value={addressFormik.values.name}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.name &&
                    Boolean(addressFormik.errors.name)
                  }
                  helperText={
                    addressFormik.touched.name && addressFormik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="receiver"
                  name="receiver"
                  label="Receiver Name"
                  value={addressFormik.values.receiver}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.receiver &&
                    Boolean(addressFormik.errors.receiver)
                  }
                  helperText={
                    addressFormik.touched.receiver &&
                    addressFormik.errors.receiver
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  label="Address"
                  value={addressFormik.values.address}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.address &&
                    Boolean(addressFormik.errors.address)
                  }
                  helperText={
                    addressFormik.touched.address &&
                    addressFormik.errors.address
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="zipcode"
                  name="zipcode"
                  label="Zip Code"
                  value={addressFormik.values.zipcode}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.zipcode &&
                    Boolean(addressFormik.errors.zipcode)
                  }
                  helperText={
                    addressFormik.touched.zipcode &&
                    addressFormik.errors.zipcode
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="contact"
                  name="contact"
                  label="Contact Number"
                  value={addressFormik.values.contact}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.contact &&
                    Boolean(addressFormik.errors.contact)
                  }
                  helperText={
                    addressFormik.touched.contact &&
                    addressFormik.errors.contact
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="country"
                  name="country"
                  label="Country"
                  value={addressFormik.values.country}
                  onChange={addressFormik.handleChange}
                  onBlur={addressFormik.handleBlur}
                  error={
                    addressFormik.touched.country &&
                    Boolean(addressFormik.errors.country)
                  }
                  helperText={
                    addressFormik.touched.country &&
                    addressFormik.errors.country
                  }
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
              }}
            >
              {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                sx={{ paddingX: 5 }}
              >
                {addressLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  'Add Address'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </AnimatedWrapper>
  );
};

export default MyEditPage;
