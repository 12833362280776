import React, { useEffect, useState, useMemo } from 'react';
import Price from '../../models/artwork/Price';
import Artist from '../../models/artist/Artist';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Paper, Box, Typography, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart-redux';
import { ApiServer } from '../../utils/axios';
import ArtworkItemCart from '../../models/artwork/ArtworkItemCart';
import BasicResponse from '../../models/common/BasicResponse';
import ArtWorkItem from '../../models/artwork/ArtWorkItem';
import useCDNResource from '../../utils/useCDNResource';

type CartItemProps = {
  id: number;
  price: Price;
  artist: Artist;
  title: string;
  material: string;
  width: number;
  height: number;
  depth: number;
  artworkId: number;
  imgPath: string;
  onDelete: (id: number) => void;
};

const CartModalItem: React.FC<CartItemProps> = ({
                                                  id,
                                                  price,
                                                  artist,
                                                  title,
                                                  material,
                                                  width,
                                                  height,
                                                  depth,
                                                  artworkId,
                                                  imgPath,
                                                  onDelete,
                                                }) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [toBeDelItem, setToBeDelItem] = useState<ArtworkItemCart | null>(null);
  const [artworkItem, setArtworkItem] = useState<ArtWorkItem | null>(null);
  const [loading, setLoading] = useState(true);
  const { getCDNResource } = useCDNResource();

  useEffect(() => {
    setLoading(true);
    ApiServer.get<BasicResponse<ArtWorkItem>>(`/artwork/item/${id}`)
        .then(({ data: { data } }) => {
          setArtworkItem(data);
        })
        .catch(() => {
          console.log('No artwork item media.');
        })
        .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    ApiServer.get<BasicResponse<ArtworkItemCart[]>>('/artwork/shopping-cart/all')
        .then(({ data: { data } }) => {
          setToBeDelItem(data.find((item) => item.artworkItem.id === id) || null);
        })
        .catch(() => {
          console.log('Error occurred');
        });
  }, [id]);

  const handleClickDelete = () => {
    setIsDisabled(true);
    ApiServer.delete(`/artwork/shopping-cart/${id}`)
        .then(() => {
          if (toBeDelItem) {
            dispatch(cartActions.removeCart({ artwork: toBeDelItem.artworkItem }));
            onDelete(id);
          }
        })
        .catch(() => {
          console.error('Failed to delete the item.');
        })
        .finally(() => setIsDisabled(false));
  };

  const thumbnail = useMemo(() => {
    if (artworkItem && artworkItem.medias && artworkItem.medias.length > 0) {
      const [thumbnailMedia] = artworkItem.medias.slice(-1);
      return getCDNResource(thumbnailMedia?.media?.path);
    }
    return imgPath;
  }, [artworkItem, getCDNResource, imgPath]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!artworkItem) {
    return <Typography>Error loading artwork item.</Typography>;
  }

  return (
      <Paper sx={{ height: '14rem' }} variant={'outlined'}>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <IconButton
              aria-label="delete"
              onClick={handleClickDelete}
              disabled={isDisabled}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem',
              height: '100%',
            }}
        >
          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '8rem' },
                mr: '1.5rem',
              }}
          >
            <img src={thumbnail || 'defaultImage.jpg'} alt="artwork preview" />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box>
                  <Typography>{title}</Typography>
                  <Typography>{artist?.name}</Typography>
                  <Typography>{material}</Typography>
                  <Typography>{`${width} X ${height} X ${depth}`}</Typography>
                </Box>
                <Box sx={{ mt: '1.8rem' }}>
                  <Typography variant={'h6'}>￦ {price.price}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};

export default CartModalItem;
