import { useState, useEffect, useCallback } from 'react';
import { ApiServer } from 'utils/axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store/redux';
import useMountEffect from './useMountEffect';

const useFavorite = (artworkId: number) => {
  const [isLiked, setIsLiked] = useState<boolean | null>(null);
  const [error, setError] = useState<any>(null);
  const [favLoading, setFavLoading] = useState(false);
  const userId = useSelector((state: RootState) => state.user.user?.id);

  // 초기 상태를 가져오는 함수
  const fetchInitialFavoriteState = useCallback(
    async (artworkId: number, userId: number) => {
      setFavLoading(true);
      return ApiServer.get(`/artwork/item/favorite/${artworkId}`, {
        params: { userId },
      })
        .then(({ data: { data } }) => {
          console.log('favorite status', data);
          return data?.status === 'ADDED';
        })
        .finally(() => {
          setFavLoading(false);
        });
    },
    []
  );

  useMountEffect(() => {
    if (userId) {
      fetchInitialFavoriteState(artworkId, userId).then((isLiked) => {
        setIsLiked(isLiked);
      });
    }
  }, []);

  // 좋아요 상태를 토글하는 함수
  const toggleLike = useCallback(() => {
    const endpoint = isLiked
      ? `/artwork/item/favorite/remove/${artworkId}`
      : `/artwork/item/favorite/add/${artworkId}`;
    setFavLoading(true);
    ApiServer.post(endpoint)
      .then(({ data: { data } }) => {
        const action = data?.status === 'ADDED' ? 'added' : 'removed';
        console.log(`successfully ${action} ${artworkId} to favorite.`);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.error('BAD REQUEST');
        } else {
          console.error(
            `Error ${isLiked ? 'adding' : 'removing'} artwork to favorite: `,
            error
          );
        }
        setError(error);
      })
      .finally(() => {
        setIsLiked((prevIsLiked) => !prevIsLiked);
        setFavLoading(false);
      });
  }, [isLiked]);

  return { isLiked, toggleLike, error, favLoading };
};

export default useFavorite;
