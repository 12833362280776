import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialTokenState = {
  AccessToken: '',
  RefreshToken: '',
};

const tokenSlice = createSlice({
  name: 'tokens',
  initialState: initialTokenState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      state.AccessToken = action.payload;
    },
    setRefreshToken(state, action: PayloadAction<string>) {
      state.RefreshToken = action.payload;
    },
  },
});

export const tokenActions = tokenSlice.actions;
export default tokenSlice.reducer;
