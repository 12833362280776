import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from '@mui/material';
import FavoriteItem from 'models/favorite/FavoriteItem';
import { Link, useLocation } from 'react-router-dom';
import { ApiServer } from 'utils/axios';
import { BasicResponse } from 'models/common';
import useCDNResource from 'utils/useCDNResource';

const ViewAllFavoriteArtworks = () => {
  const location = useLocation();
  const passedState = location.state as { favoriteArtworks: FavoriteItem[] };
  const [favoriteArtworks, setFavoriteArtworks] = useState<FavoriteItem[]>(
      passedState?.favoriteArtworks || []
  );
  const { getCDNResource } = useCDNResource();

  useEffect(() => {
    if (!favoriteArtworks.length) {
      ApiServer.get<BasicResponse<FavoriteItem[]>>(`/artwork/favorite/all`)
          .then(({ data: { data } }) => {
            setFavoriteArtworks(data);
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, [favoriteArtworks]);

  const handleArtworkClick = (id: number) => {
    // Implement the artwork click handler logic, for example, navigate to the artwork detail page
    console.log(`Artwork clicked: ${id}`);
  };

  const addedArtworks = favoriteArtworks?.filter(
      (artwork) => artwork.status === 'ADDED'
  );

  const thumbnails = useMemo(() => {
    return addedArtworks?.map((favArtwork) => {
      const [thumbnailMedia] = favArtwork.artworkItem.medias?.slice(-1) ?? [];
      return {
        id: favArtwork.id,
        path: getCDNResource(thumbnailMedia?.media?.path),
      };
    });
  }, [addedArtworks, getCDNResource]);

  return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          All my favorites
        </Typography>
        <Grid container spacing={2}>
          {addedArtworks?.map((favArtwork) => {
            const thumbnail = thumbnails?.find((thumb) => thumb.id === favArtwork.id)?.path;

            return (
                <Grid item xs={12} sm={6} md={3} key={favArtwork.id}>
                  <Link
                      to={`/merchandises/${favArtwork.artworkItem.id}`}
                      style={{ textDecoration: 'none' }}
                  >
                    <Card sx={{ maxWidth: 345, borderRadius: 0 }}>
                      <CardActionArea
                          onClick={() => handleArtworkClick(favArtwork.artworkItem.id)}
                      >
                        <CardMedia
                            component="img"
                            height="250"
                            image={thumbnail || 'defaultImage.jpg'}
                            alt={favArtwork.artworkItem.title}
                        />
                        <Box sx={{ padding: '1rem' }}>
                          <Typography gutterBottom variant="h6" component="div">
                            {favArtwork.artworkItem.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {favArtwork.artworkItem.artist?.name}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Link>
                </Grid>
            );
          })}
        </Grid>
      </Box>
  );
};

export default ViewAllFavoriteArtworks;
