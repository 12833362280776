import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormTextField from '../../components/common/FormTextField';
import useCDNResource from '../../utils/useCDNResource';
import { ApiServer } from '../../utils/axios';
import styles from './auth.module.css';
import useMountEffect from '../../hooks/useMountEffect';
import { UseSelector } from 'react-redux/es/hooks/useSelector';
import { RootState } from 'store/redux';
import { useTranslation } from 'react-i18next';

type SignUpValue = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthday: string;
  termsAccepted: boolean;
};

const SignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCDNResource } = useCDNResource();
  const { control, handleSubmit, watch, setError, clearErrors, register } =
    useForm<SignUpValue>();
  const [errorMessage, setErrorMessage] = useState('');
  const language = useSelector((state: RootState) => state.lang.lang);
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<SignUpValue> = (data) => {
    if (!data.termsAccepted) {
      setError('termsAccepted', {
        type: 'manual',
        message: t(`s.signup.agree.error-message`),
      });
      return;
    }

    ApiServer.post('/auth/signup', data)
      .then(() => {
        navigate('/login'); // Redirect to login after successful signup
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message || 'AN ERROR OCCURRED.');
      });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      clearErrors('termsAccepted');
    }
  };

  useMountEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        margin: 0,
        padding: 0,
        backgroundColor: '#f5f5f5',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          maxWidth: '400px',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: 'none', // Remove elevation
        }}
      >
        <Box
          component="img"
          width="60%"
          height="auto"
          src={getCDNResource('/logo/horizontal_logo.webp')}
          alt="Logo"
          sx={{ mb: '2rem' }}
        />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          gap={2}
          width="100%"
        >
          <FormTextField
            name="firstName"
            type="text"
            label={t(`w.common.info.first-name`)}
            control={control}
            fullWidth
            required
          />
          <FormTextField
            name="lastName"
            type="text"
            label={t(`w.common.info.last-name`)}
            control={control}
            fullWidth
            required
          />
          <FormTextField
            name="email"
            type="email"
            label={t(`w.common.info.email`)}
            autoComplete="email"
            control={control}
            fullWidth
            required
          />
          <FormTextField
            name="password"
            type="password"
            label={t(`w.common.info.password`)}
            control={control}
            fullWidth
            required
          />
          <FormTextField
            name="birthday"
            type="date"
            label={t(`w.common.info.birthday`)}
            InputLabelProps={{ shrink: true }}
            control={control}
            fullWidth
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                {...register('termsAccepted')}
                onChange={handleCheckboxChange}
              />
            }
            label={t(`s.signup.agree.terms-and-conditions`)}
          />
          <FormHelperText error>
            {watch('termsAccepted') ? '' : t(`s.signup.agree.error-message`)}
          </FormHelperText>
          <Typography>
            {t(`s.signup.nav-to.alr-have-acc`)}{' '}
            <Link to={'/login'} className={styles.customLink}>
              {t(`w.signup.func.login`)}
            </Link>
          </Typography>
          <Button type="submit" fullWidth variant="contained" color="primary">
            {t(`w.signup.func.signup`)}
          </Button>
          <FormHelperText sx={{ minHeight: '20px' }} error>
            {errorMessage}
          </FormHelperText>
        </Box>
      </Paper>
    </Box>
  );
};

export default SignUpPage;
