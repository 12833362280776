import React from "react";
import { Box } from "@mui/material";

const ArtworkSoldOverlay: React.FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "10px",
        left: "10px",
        width: "60px",
        height: "60px",
        backgroundImage:
          'url("https://gallery-soma-assets.s3.ap-northeast-2.amazonaws.com/logo/somalogoSOLD.png")',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

export default ArtworkSoldOverlay;
