import React from 'react';
import { Box, Typography, Button, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './ExhibitionNavigator.module.css';

type ExhibitionNavigatorProps = {
  onButtonClick: (value: 'PAST' | 'ONGOING' | 'UPCOMING') => void;
  id: string;
};

const ExhibitionNavigator: React.FC<ExhibitionNavigatorProps> = ({
  onButtonClick,
  id,
}) => {
  const { t } = useTranslation();

  const buttonClickHandler = (value: 'PAST' | 'ONGOING' | 'UPCOMING') => {
    onButtonClick(value);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ width: '80%' }}
    >
      <Box sx={{ marginBottom: '3rem' }}>
        <Typography color="black" variant="h2" align="center">
          {id.toUpperCase()}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          marginTop: '1rem',
          width: '100%',
          maxWidth: '1200px',
          gap: 2,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} md={3} display="flex" justifyContent="center">
          <Button
            onClick={() => buttonClickHandler('ONGOING')}
            className={styles.button}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              padding: '15px 30px',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'grey',
                color: 'white',
              },
            }}
          >
            <Typography variant="button" color="black">
              {t('w.exhibition.chronological.ongoing-exhibition')}
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm={4} md={3} display="flex" justifyContent="center">
          <Button
            onClick={() => buttonClickHandler('PAST')}
            className={styles.button}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              padding: '15px 30px',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'grey',
                color: 'white',
              },
            }}
          >
            <Typography variant="button" color="black">
              {t('w.exhibition.chronological.past-exhibition')}
            </Typography>
          </Button>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm={4} md={3} display="flex" justifyContent="center">
          <Button
            onClick={() => buttonClickHandler('UPCOMING')}
            className={styles.button}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              padding: '15px 30px',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'grey',
                color: 'white',
              },
            }}
          >
            <Typography variant="button" color="black">
              {t('w.exhibition.chronological.upcoming-exhibition')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExhibitionNavigator;
