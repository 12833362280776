import BasicResponse from "../../models/common/BasicResponse";
import ArtWorkItem from "../../models/artwork/ArtWorkItem";
import { Params } from "react-router-dom";
import { ApiServer } from "../axios";

export async function loader({ params }: { params: Params<string> }) {
  const id = params.artworkId;
  const response = await ApiServer.get<BasicResponse<ArtWorkItem>>(
    `/artwork/item/${id}`,
  );
  if (response.data.status.code === "OK") {
    return response.data.data;
  } else {
    return {
      status: "something went wrong",
      content: null,
    };
  }
}
